import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "..";
import CommonInput from "../Input";
import CommonSelect from "../Select";

const AddIncrement = ({ handleClose, handelClick, formik }) => {
  console.log("formik", formik);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Add Increment
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
          <Grid item xs={12} sm={6}>
            <CommonInput
              labelinput="Designation"
              type="text"
              formik={formik}
              name="designation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              Type
            </Typography>
            <CommonSelect
              selectHead="Select Type"
              selectList={[
                {
                  id: 1,
                  name: "Percentage",
                },
                {
                  id: 2,
                  name: "Flat",
                },
              ]}
              value={formik.values.type}
              onChange={(e) => formik.setFieldValue("type", e.target.value)}
              formik={formik}
              name="type"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonInput formik={formik} labelinput="Increment" name="amount" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="Total salary "
              type="text"
              formik={formik}
              name="totalSalary"
              disabled={true}
              value={
                formik.values.type == 1
                  ? parseFloat(
                      (formik.values.basicSalary * formik.values.amount) / 100
                    ) + parseFloat(formik.values.basicSalary)
                  : parseFloat(formik.values.basicSalary) +
                    parseFloat(formik.values.amount)
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput="Description"
              type="text"
              formik={formik}
              name="description"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput="Applicable from "
              type="date"
              formik={formik}
              name="applicableFrom"
              isDate={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Submit"
          size="small"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default AddIncrement;
