import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./helper/ScrollToTop";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import UserManagement from "./pages/UserManagement/UserManagement.jsx";
import LeaveandHolidayManage from "./pages/Leave and Holiday Management/LeaveandHolidayManage.jsx";
import Notifications from "./pages/Notifications and Alerts/Notifications.jsx";
import FormContainer from "./pages/Employee Data/Employee Add/FormContainer.jsx";
import EmployeeMain from "./pages/Employee Data/EmployeeMain.jsx";
import EmployeeDetails from "./pages/Employee Data/EmpInfo/EmployeeDetails.jsx";
import Role from "./pages/Role management/Role.jsx";
import CreateRole from "./pages/Role management/CreateRole.jsx";
import ApproveLeave from "./pages/Leave and Holiday Management/ApproveLeave.jsx";
import RejectLeave from "./pages/Leave and Holiday Management/RejectLeave.jsx";
import ProfileSetting from "./pages/Profile Setting/ProfileSetting.jsx";
import Payrole from "./pages/Salary and Tax Management/Payrole.jsx";
import SalaryandTaxMang from "./pages/Salary and Tax Management/SalaryandTaxMang.jsx";
import ViewPayslip from "./pages/Salary and Tax Management/Payslipgeneration/ViewPayslip.jsx";
import PayslipGeneration from "./pages/Salary and Tax Management/Payslipgeneration/PayslipGeneration.jsx";
import EmployeeSalary from "./pages/Salary and Tax Management/EmployeeSalary/EmployeeSalary.jsx";
import CompanyModule from "./pages/Company-Module/CompanyModule.jsx";
import AddCompany from "./pages/Company-Module/AddCompany.jsx";
import AddUsermodel from "./pages/UserManagement/AddUsermodel.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useUserNotificationSubscribeHook } from "./Notification.js";
import { loadUser } from "./Service/auth.service";
import { loginUserApi, logoutApi } from "./state/Action/authAction";
import Notification from "./components/Common/Notification/Notification.js";
import { Modal } from '../src/components/Common';
import PrivateRoute from "./Routes/PrivateRoute";
import HolidayManagement from "./pages/HolidayManagement/HolidayManagement.jsx";
import Company_Details from "./pages/Company-Module/Company_Details.jsx";

const Login = React.lazy(() => import("./pages/Login/Login"));
const Layout = React.lazy(() => import("./components/layouts/Layout"));
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard.js"));
const NoDataFound = React.lazy(() => import("./pages/NoDataFound/NoDataFound.js"));

// const pages = [
//   { name: "dashboard", path: "dashboard", element: <Dashboard /> },
//   { name: "User Management", path: "user", element: <UserManagement /> },
//   { name: "Role Management", path: "role", element: <Role /> },
//   { name: "Holiday Management", path: "holiday", element: <HolidayManagement /> },
//   { name: "Employee Data", path: "employee", element: <EmployeeMain /> },
//   { name: "Employee Details", path: "employee-details", element: <EmployeeDetails /> },
//   { name: "createrole", path: "create-role", element: <CreateRole /> },
//   { name: "Salary and Tax Management", path: "salaryandtaxmanag", element: <SalaryandTaxMang /> },
//   { name: "Payslip Generator", path: "payslipgenerator", element: <PayslipGeneration /> },
//   { name: "Leave and Holiday Management", path: "leaveandholidayManage", element: <LeaveandHolidayManage /> },
//   { name: "Notification", path: "notification", element: <Notifications /> },
//   { name: "add employee", path: "FormContainer", element: <FormContainer /> },
//   { name: "payrole", path: "payroll", element: <Payrole /> },
//   { name: "view payslip", path: "view-payslip", element: <ViewPayslip /> },
//   { name: "approve leave", path: "approve-leave", element: <ApproveLeave /> },
//   { name: "reject leave", path: "reject-leave", element: <RejectLeave /> },
//   { name: "profilesetting", path: "profile-settings", element: <ProfileSetting /> },
//   { name: 'employeesalary', path: 'employee-salary', element: <EmployeeSalary /> },
//   { name: 'Companymodule', path: 'company', element: <CompanyModule /> },
//   { name: 'Add Company', path: 'add-company', element: <AddCompany /> },
//   { name: 'Add user', path: 'add-user', element: <AddUsermodel /> },
//   { name: 'Companydetails', path: 'company-details', element: <Company_Details /> },

// ];

const pages = [
  { name: "Dashboard", path: "dashboard", element: <Dashboard /> },
  { name: "User Management", path: "user", element: <UserManagement /> },
  { name: "Role Management", path: "role", element: <Role /> },
  { name: "Holiday Managenent", path: "holiday", element: <HolidayManagement /> },
  { name: "Employee Management", path: "employee", element: <EmployeeMain /> },
  { name: "Employee Management", path: "employee-details", element: <EmployeeDetails /> },
  { name: "Role Management", path: "create-role", element: <CreateRole /> },
  { name: "Salary", path: "salaryandtaxmanag", element: <SalaryandTaxMang /> },
  { name: "Pay Slip Generator", path: "payslipgenerator", element: <PayslipGeneration /> },
  { name: "Leave Management", path: "leaveandholidayManage", element: <LeaveandHolidayManage /> },
  { name: "Notification", path: "notification", element: <Notifications /> },
  { name: "Employee Management", path: "employee-add", element: <FormContainer /> },
  { name: "Employee Management", path: "employee-edit", element: <FormContainer /> },
  { name: "Payrole", path: "payroll", element: <Payrole /> },
  { name: "Pay Slip Generator", path: "view-payslip", element: <ViewPayslip /> },
  { name: "Leave Management", path: "approve-leave", element: <ApproveLeave /> },
  { name: "Leave Management", path: "reject-leave", element: <RejectLeave /> },
  { name: "profile", path: "profile-settings", element: <ProfileSetting /> },
  { name: 'Salary', path: 'employee-salary', element: <EmployeeSalary /> },
  { name: 'Company Management', path: 'company', element: <CompanyModule /> },
  { name: 'Company Management', path: 'add-company', element: <AddCompany /> },
  { name: 'User Management', path: 'add-user', element: <AddUsermodel /> },
  { name: 'Company Management', path: 'company-details', element: <Company_Details /> },
];

function App() {
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  const { registerAndSubscribe } = useUserNotificationSubscribeHook();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: '',
    isEdit: false,
    isView: false,
    id: ''
  });
  const [roleName, setRoleName] = useState("admin");
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { show } = useSelector(state => state?.root?.notification);
  const { user } = useSelector((state) => state?.root?.auth);
  const { auth } = useSelector((state) => state?.root);

  useEffect(() => {
    if (pathname == "/" && user && user?.role && user?.role) {
      Navigate("/dashboard");
    }
    setRoleName(user && user?.role);
  }, [user, pathname]);

  const handelLogout = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: '',
      head: '',
      id: ''
    });
    localStorage.removeItem('token');
    dispatch(logoutApi());

    Navigate('/');
  }

  useEffect(() => {
    if (auth && auth?.isExpired) {
      setIsModalOpen({
        open: true,
        currentComponent: "logout",
        para: 'Logged out due to inactivity. Please log in again to continue.',
        head: 'Session Expired',
      });
    }
  }, [auth]);

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      registerAndSubscribe(res?.data && res?.data?.id);
      dispatch(loginUserApi(res?.data)).then(() => {
        setIsUserLoaded(true);
      }).catch(() => {
        setIsUserLoaded(true);
      })
    } else {
      setIsUserLoaded(true);
    }
  };


  useEffect(() => {
    const token = localStorage.getItem("token");
    const payload = {
      token: token,
    };
    getUsetData(payload);
  }, []);


  useEffect(() => {
    if (show && show.show) {
      setShowNotification(show);
    }
  }, [show]);
  return (
    <>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <div className="background_color">
            {/* <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={
                  !isUserLoaded ? (
                    <Loader />
                  ) : user && user?.role ? (
                    <Layout>
                      <Dashboard />
                    </Layout>
                  ) : (
                    <Login />
                  )
                } />
              </Routes>
            </Suspense> */}
            <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</div>}>
              <Routes>

                <Route path="/" element={<Login />} />
                {pages?.map((page, index) => {
                  const { name, path, element } = page;
                  const permission = user?.permission?.find((perm) => perm.pageName === name);

                  return (permission && permission.readP === 1 || user.role == 1 || name == "profile" || name == "dashboard") ? (
                    <Route key={index} exact path={`/${path}`} element={
                      <PrivateRoute>
                        <Layout>{element}</Layout>
                      </PrivateRoute>
                    }
                    />
                  ) : (permission && permission.readP && permission.readP == 2 || permission && permission.readP == 0) ? (
                    <Route path="*" element={<NoDataFound />} />
                  ) : null;

                })}

              </Routes>
            </Suspense>

          </div>
          {showNotification && showNotification.show ? <Notification show={showNotification} setShow={setShowNotification} /> : null}
          <Modal
            modalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleClose={() => {
              return;
            }}
            handelClick={handelLogout}
          />
        </ThemeProvider>
      </ScrollToTop >
    </>
  );
}

export default App;

// import React, { useEffect, useState } from "react";
// import { Route, Routes, useNavigate } from "react-router-dom";
// import "./App.css";
// import ScrollToTop from "./helper/ScrollToTop";
// import { ThemeProvider } from "@mui/material";
// import { theme } from "./helper/Theme";
// import UserManagement from "./pages/UserManagement/UserManagement.jsx";
// import LeaveandHolidayManage from "./pages/Leave and Holiday Management/LeaveandHolidayManage.jsx";
// import Notifications from "./pages/Notifications and Alerts/Notifications.jsx";
// import FormContainer from "./pages/Employee Data/Employee Add/FormContainer.jsx";
// import EmployeeMain from "./pages/Employee Data/EmployeeMain.jsx";
// import EmployeeDetails from "./pages/Employee Data/EmpInfo/EmployeeDetails.jsx";
// import Role from "./pages/Role management/Role.jsx";
// import CreateRole from "./pages/Role management/CreateRole.jsx";
// import ApproveLeave from "./pages/Leave and Holiday Management/ApproveLeave.jsx";
// import RejectLeave from "./pages/Leave and Holiday Management/RejectLeave.jsx";
// import ProfileSetting from "./pages/Profile Setting/ProfileSetting.jsx";
// import Payrole from "./pages/Salary and Tax Management/Payrole.jsx";
// import SalaryandTaxMang from "./pages/Salary and Tax Management/SalaryandTaxMang.jsx";
// import ViewPayslip from "./pages/Salary and Tax Management/Payslipgeneration/ViewPayslip.jsx";
// import PayslipGeneration from "./pages/Salary and Tax Management/Payslipgeneration/PayslipGeneration.jsx";
// import EmployeeSalary from "./pages/Salary and Tax Management/EmployeeSalary/EmployeeSalary.jsx";
// import CompanyModule from "./pages/Company-Module/CompanyModule.jsx";
// import AddCompany from "./pages/Company-Module/AddCompany.jsx";
// import AddUsermodel from "./pages/UserManagement/AddUsermodel.jsx";
// import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import { useUserNotificationSubscribeHook } from "./Notification.js";
// import { loadUser } from "./Service/auth.service";
// import { loginUserApi, logoutApi } from "./state/Action/authAction";
// import Notification from "./components/Common/Notification/Notification.js";
// import { Modal } from '../src/components/Common';
// import PrivateRoute from "./Routes/PrivateRoute";
// import HolidayManagement from "./pages/HolidayManagement/HolidayManagement.jsx";
// import Company_Details from "./pages/Company-Module/Company_Details.jsx";

// const Login = React.lazy(() => import("./pages/Login/Login"));
// const Layout = React.lazy(() => import("./components/layouts/Layout"));
// const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard.js"));
// const NoDataFound = React.lazy(() => import("./pages/NoDataFound/NoDataFound.js"));

// const pages = [
//   { name: "dashboard", path: "dashboard", element: <Dashboard /> },
//   { name: "User Management", path: "user", element: <UserManagement /> },
//   { name: "Role Management", path: "role", element: <Role /> },
//   { name: "Holiday Managenent", path: "holiday", element: <HolidayManagement /> },
//   { name: "Employee Management", path: "employee", element: <EmployeeMain /> },
//   { name: "Employee Management", path: "employee-details", element: <EmployeeDetails /> },
//   { name: "Role Management", path: "create-role", element: <CreateRole /> },
//   { name: "Salary", path: "salaryandtaxmanag", element: <SalaryandTaxMang /> },
//   { name: "Pay Slip Generator", path: "payslipgenerator", element: <PayslipGeneration /> },
//   { name: "Leave Management", path: "leaveandholidayManage", element: <LeaveandHolidayManage /> },
//   { name: "Notification", path: "notification", element: <Notifications /> },
//   { name: "Employee Management", path: "employee-add", element: <FormContainer /> },
//   { name: "Payrole", path: "payroll", element: <Payrole /> },
//   { name: "Pay Slip Generator", path: "view-payslip", element: <ViewPayslip /> },
//   { name: "Leave Management", path: "approve-leave", element: <ApproveLeave /> },
//   { name: "Leave Management", path: "reject-leave", element: <RejectLeave /> },
//   { name: "profile", path: "profile-settings", element: <ProfileSetting /> },
//   { name: 'Salary', path: 'employee-salary', element: <EmployeeSalary /> },
//   { name: 'Company Management', path: 'company', element: <CompanyModule /> },
//   { name: 'Company Management', path: 'add-company', element: <AddCompany /> },
//   { name: 'User Management', path: 'add-user', element: <AddUsermodel /> },
//   { name: 'Company Management', path: 'company-details', element: <Company_Details /> },
// ];

// function App() {
//   const [showNotification, setShowNotification] = useState({
//     show: false,
//     type: '',
//     message: ''
//   });
//   const { registerAndSubscribe } = useUserNotificationSubscribeHook();

//   const [isModalOpen, setIsModalOpen] = useState({
//     open: false,
//     currentComponent: "",
//     para: '', head: '',
//     isEdit: false,
//     isView: false,
//     id: ''
//   });
//   const [roleName, setRoleName] = useState("admin");
//   const [isUserLoaded, setIsUserLoaded] = useState(false);

//   const location = useLocation();
//   const { pathname } = location;
//   const dispatch = useDispatch();
//   const Navigate = useNavigate();

//   const { show } = useSelector(state => state?.root?.notification);
//   const { user } = useSelector((state) => state?.root?.auth);
//   const { auth } = useSelector((state) => state?.root);

//   useEffect(() => {
//     if (pathname == "/" && user && user?.role && user?.role) {
//       Navigate("/dashboard");
//     }
//     setRoleName(user && user?.role);
//   }, [user, pathname]);

//   const handelLogout = () => {
//     setIsModalOpen({
//       open: false,
//       currentComponent: "",
//       para: '',
//       head: '',
//       id: ''
//     });
//     localStorage.removeItem('token');
//     dispatch(logoutApi());

//     Navigate('/');
//   }

//   useEffect(() => {
//     if (auth && auth?.isExpired) {
//       setIsModalOpen({
//         open: true,
//         currentComponent: "logout",
//         para: 'Logged out due to inactivity. Please log in again to continue.',
//         head: 'Session Expired',
//       });
//     }
//   }, [auth]);

//   const getUsetData = async (data) => {
//     let res = await loadUser(data);
//     if (res?.status) {
//       registerAndSubscribe(res?.data && res?.data?.id);
//       dispatch(loginUserApi(res?.data)).then(() => {
//         setIsUserLoaded(true);
//       }).catch(() => {
//         setIsUserLoaded(true);
//       })
//     } else {
//       setIsUserLoaded(true);
//     }
//   };


//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     const payload = {
//       token: token,
//     };
//     getUsetData(payload);
//   }, []);


//   useEffect(() => {
//     if (show && show.show) {
//       setShowNotification(show);
//     }
//   }, [show]);

//   console.log('useruseruseruser', user);
//   return (
//     <ThemeProvider theme={theme}>
//       <ScrollToTop>
//         <div className="background_color">
//           <Routes>
//             <Route path="/" element={<Login />} />
//             {pages?.map((page, index) => {
//               const { name, path, element } = page;
//               const permission = user?.permission?.find((perm) => perm.pageName === name);

//               const hasPermission = permission && permission.readP === 1;
//               const isAdmin = user.role === 1;
//               const isForbidden = permission && (permission.readP === 2 || permission.readP === 0);

//               if (hasPermission || isAdmin) {
//                 return (
//                   <Route key={index} path={`/${path}`} element={<Layout>{element}</Layout>} />
//                 );
//               }

//               if (isForbidden) {
//                 return (
//                   <Route key={index} path={path} element={<h1>Not Found</h1>} />
//                 );
//               }

//               return null; // Handle cases where there is no permission
//             })}
//           </Routes>

//         </div>
//       </ScrollToTop>
//     </ThemeProvider>
//   );
// }

// export default App;

