import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { BackButton, Button, Heading, Input } from "../../components/Common";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import img from "../../../src/assets/images/main_logo.png";
import { useSelector } from "react-redux";
import { adminDetailInitialValues } from "../../helper/initialValues";
import { useFormik } from "formik";
import { AdminDetail } from "../../Service/Admin.service";

const ProfileSettings = () => {
  const [edit, setEdit] = useState();

  const [editPassWord, setEditPassword] = useState();
  const handleClick = () => setEdit((show) => !show);
  const handlePasswordClick = () => setEditPassword((show) => !show);
  const [file, setFile] = useState({});

  const { user } = useSelector((state) => state?.root?.auth || {});
  console.log("dsdsdsdsdsdsds", user);

  const formik = useFormik({
    initialValues: adminDetailInitialValues,
    // validationSchema: adminDetailValidationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
    },
  });

  useEffect(() => {
    if (user?.id) {
      getAdminDetails(user?.id);
    }
  }, [user]);

  const getAdminDetails = async (id) => {
    const res = await AdminDetail(id);

    if (res?.status) {
      formik.setValues({
        firstName: res.data.firstName || "",
        lastName: res.data.lastName || "",
        email: res.data.email || "",
        phone: res.data.phone || "",
        address1: res.data.address1 || "",
        address2: res.data.address2 || "",
        country: res.data.country || "",
        state: res.data.state || "",
        city: res.data.city || "",
        zipCode: res.data.zipCode || "",
      });
    }
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-start"}
        gap={1}
        alignItems={"center"}
        mb={2}
      >
        <BackButton />
        <Heading head={"Profile Settings"} />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box className="card_container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container sx={{ width: { xs: "100%", sm: "100%" } }}>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    {!edit ? (
                      <Box
                        sx={{
                          width: "110px",
                          height: "110px",
                          background: "#f1f1f1",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {file ? (
                          <img
                            src={img}
                            width={110}
                            height={110}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              objectFit: "contain",
                              padding: "5px",
                            }}
                          />
                        ) : (
                          <FaRegUser
                            style={{ fontSize: "50px", color: "#00000091" }}
                          />
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "110px",
                          height: "110px",
                          background: "#f1f1f1",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        {file ? (
                          <img
                            src={img}
                            width={110}
                            height={110}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <FaRegUser
                            style={{ fontSize: "50px", color: "#00000091" }}
                          />
                        )}
                        <Box
                          sx={{
                            position: "absolute",
                            cursor: "pointer",
                            bottom: "14px",
                            right: "0px",
                            borderRadius: "50%",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              width: "22px",
                              height: "22px",
                            }}
                          >
                            <MdOutlineModeEditOutline
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                background: "#ffc107",
                                color: "#fff",
                                fontSize: "22px",
                                padding: "4px",
                                borderRadius: "50%",
                              }}
                            />
                            <input
                              type="file"
                              style={{
                                position: "absolute",
                                opacity: "0",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                right: "0",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    marginTop={1}
                  >
                    <Typography fontSize={"18px"} fontWeight={500}>
                      Asia Pacific
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ my: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"FirstName"}
                      variant="standard"
                      name="firstName"
                      maxLength={100}
                      disabled={edit ? false : true}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"LastName"}
                      variant="standard"
                      name="lastName"
                      maxLength={100}
                      disabled={edit ? false : true}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Email"}
                      variant="standard"
                      name="email"
                      maxLength={50}
                      disabled={true}
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Phone"}
                      variant="standard"
                      name="phone"
                      maxLength={10}
                      formik={formik}
                      disabled={edit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      labelinput={"Address Line 1"}
                      variant="standard"
                      name="address1"
                      formik={formik}
                      maxLength={150}
                      disabled={edit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      labelinput={"Address Line 2"}
                      variant="standard"
                      name="address2"
                      formik={formik}
                      maxLength={150}
                      disabled={edit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Country"}
                      variant="standard"
                      name="country"
                      maxLength={20}
                      formik={formik}
                      disabled={edit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"State"}
                      variant="standard"
                      name="state"
                      maxLength={20}
                      formik={formik}
                      disabled={edit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"City"}
                      variant="standard"
                      name="city"
                      maxLength={20}
                      formik={formik}
                      disabled={edit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"ZipCode"}
                      variant="standard"
                      name="zipCode"
                      maxLength={6}
                      formik={formik}
                      disabled={edit ? false : true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  gap={1}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  {edit ? (
                    <>
                      <Button buttonName={"Cancle"} onClick={handleClick} />
                      <Button
                        buttonName={"Save"}
                        onClick={formik.handleSubmit}
                      />
                    </>
                  ) : (
                    <Button buttonName={"Edit"} onClick={handleClick} />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileSettings;
