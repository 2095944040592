import { Container, Divider, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import { Heading } from '../../../src/components/Common'


const PayrollForm = ({ onCalculate }) => {
  const [formData, setFormData] = useState({
    employeeId: '',
    basicSalary: '',
    hra: '',
    otherAllowances: '',
    taxDeductions: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCalculate(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Employee ID"
            name="employeeId"
            value={formData.employeeId}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            placeholder="Basic Salary"
            name="basicSalary"
            type="number"
            value={formData.basicSalary}
            onChange={handleChange}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            placeholder="Tax Deductions"
            name="taxDeductions"
            type="number"
            value={formData.taxDeductions}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Calculate
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};


const Payrole = () => {
  const [result, setResult] = useState(null);

  const calculatePayroll = (data) => {
    const { basicSalary,  taxDeductions } = data;
    const grossSalary = parseFloat(basicSalary) ;
    const netSalary = grossSalary - parseFloat(taxDeductions);

    setResult({
      grossSalary,
      netSalary,
    });
  };
  return (
    <>
      <Stack gap={2}>
      <Heading head={"Payroll Calculation"}/>
      <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        {/* <Typography variant="h6" gutterBottom>
          Payroll Calculator
        </Typography> */}
        <PayrollForm onCalculate={calculatePayroll} />
        {result && (
          <>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant="h6">Gross Salary: {result.grossSalary.toFixed(2)}</Typography>
            <Typography variant="h6">Net Salary: {result.netSalary.toFixed(2)}</Typography>
          </>
        )}
      </Paper>
    </Container>
      </Stack>
    </>
  )
}

export default Payrole