import { deleteRequest, get, post, put } from "../web.request";

export const RoleList = async (query) => {
  return await get(`/roleList${query}`)
}

export const RoleCreate = async (data) => {
  return await post(`/addRole `, data);
}

export const RoleUpdate = async (id, data) => {
  return await put(`/updateRole/${id}`, data);
}

export const RoleDetail = async (id) => {
  return await get(`/roleDetail/${id}`);
}

export const RoleDelete = async (id) => {
  return await deleteRequest(`/deleteRole/${id}`);
}

export const RoleDropdown = async () => {
  return await get(`/roleDropdown`);
}

export const RoleStatusChange = async (id, data) => {
  return await put(`/statusChangeRole/${id}`, data);
}

export const PageList = async () => {
  return await get(`/pageListAdmin`)
}
