import React, { useEffect, useState } from "react";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import {
  Box,
  Collapse,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { VscFilter } from "react-icons/vsc";
import Search from "../../components/Common/Table/Search";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from "react-icons/md";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { RoleDropdown } from "../../Service/Role.service";
import moment from "moment";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import { Pagination } from "../../components/Common/Table";
import {
  DeleteHoliday,
  HolidayList,
  StatusChangeHoliday,
} from "../../Service/Holiday.service";
import { filterHoliday } from "../../helper/initialValues";
import CommonSelect from "../../components/Common/Select";
import { CompanyDropdown } from "../../Service/Company.service";
import { set } from "react-hook-form";

const HolidayManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [holidaydata, setHolidayData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [roledata, setRoleData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [showFilter, setShowFilter] = useState(false);
  const [companyData, setCompanyData] = useState([]);

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
    formik1.resetForm();
    getHolidayList();
  };

  const dispatch = useDispatch();
  const formik1 = useFormik({
    initialValues: filterHoliday,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getHolidayList();
    },
  });

  const getHolidayList = async () => {
    setHolidayData([]);
    let selectQry = `?page=${page + 1
      }&limit=${rowsPerPage}&search=${search}&status=${formik1.values.status
      }&sortBy=${sortConfig.key}&sortFlag=${sortConfig.direction}&companyId=${formik1.values.company
      }`;
    let res = await HolidayList(selectQry);
    if (res?.status) {
      setHolidayData(res.data);
      setTotalData(res.total);
    } else {
      setHolidayData(null);
    }
  };

  const getCompanyDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  useEffect(() => {
    getCompanyDropdownData();
  }, []);

  useEffect(() => {
    getHolidayList();
  }, [search, page, rowsPerPage, sortConfig]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await StatusChangeHoliday(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
          ? await DeleteHoliday(isModalOpen?.id)
          : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getHolidayList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };

  const getDropdownData = async () => {
    let res = await RoleDropdown();
    if (res?.status) {
      setRoleData(res.data);
    } else {
      setRoleData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  return (
    <>
      <Stack gap={2}>
        <Heading head={"Holiday"} />
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={'wrap'}
          gap={2}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button
              buttonName={"Create"}
              size="small"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "addholiday",
                });
              }}
            />
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              onClick={handleFilterClick}
            />
          </Stack>
        </Stack>
        <Collapse Collapse in={showFilter}>
          <Stack
            mb={"12px"}
            sx={{ background: "#151515" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Typography className="inputfont">{"Company"}:</Typography>
              <CommonSelect
                name="company"
                value={formik1.values.company}
                formik={formik1}
                selectList={companyData}
                onChange={(e) =>
                  formik1.setFieldValue("company", e.target.value)
                }
                selectHead="Select Company"
              />
            </Grid>

            <Grid className="filterinput">
              <Typography className="inputfont">{"Status"}:</Typography>
              <CommonSelect
                name="status"
                value={formik1.values.status}
                formik={formik1}
                selectList={[
                  { id: 1, name: "Active" },
                  { id: 0, name: "Inactive" },
                  { id: 2, name: "Delete" },
                ]}
                onChange={(e) =>
                  formik1.setFieldValue("status", e.target.value)
                }
                selectHead="Select Status"
              />
            </Grid>

            <Box>
              <Button buttonName={"Filter"} onClick={formik1.handleSubmit} />
            </Box>
            <Box>
              <Button buttonName={"Clear"} onClick={() => clearFilter()} />
            </Box>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Title</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Company</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Date</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Day</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Type</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Status</div>
                  </Stack>
                </TableCell>

                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"left"}
                  >
                    <div>Action</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidaydata &&
                holidaydata?.map((data) => (
                  <TableRow
                    key={data?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                  >
                    <TableCell
                      className="table-hover"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        setIsModalOpen({
                          open: true,
                          currentComponent: "viewholiday",
                          isEdit: true,
                          id: data?.id,
                        });
                      }}
                    >
                      {data?.title}
                    </TableCell>
                    <TableCell
                      className="table-hover"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        setIsModalOpen({
                          open: true,
                          currentComponent: "viewholiday",
                          isEdit: true,
                          id: data?.id,
                        });
                      }}
                    >
                      {data?.companyname}
                    </TableCell>
                    <TableCell
                      className="table-hover"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        setIsModalOpen({
                          open: true,
                          currentComponent: "viewuser",
                          isEdit: true,
                          id: data?.id,
                        });
                      }}
                    >
                      {moment(data?.date).format("DD/MM/YYYY")}

                    </TableCell>

                    <TableCell
                      className="table-hover"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        setIsModalOpen({
                          open: true,
                          currentComponent: "viewholiday",
                          isEdit: true,
                          id: data?.id,
                        });
                      }}
                    >
                      {data?.day}
                    </TableCell>
                    <TableCell
                      className="table-hover"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        setIsModalOpen({
                          open: true,
                          currentComponent: "viewholiday",
                          isEdit: true,
                          id: data?.id,
                        });
                      }}
                    >
                      {data?.type == 1 ? "Public" : "Composite"}
                    </TableCell>
                    <TableCell
                      className="table-hover"
                      style={{ textAlign: "left" }}
                    >
                      <Switch
                        size="small"
                        checked={data?.status === 1 ? true : false}
                        disabled={formik1.values.status == 2 ? true : false}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsModalOpen({
                            id: data?.id,
                            open: true,
                            currentComponent: "status",
                            head: "Change Status",
                            para: "Are you sure you want to Change the Status?",
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger className="chatDropDown">
                          <MenuButton
                            icon={
                              <MdMoreVert
                                style={{ color: "#000", fontSize: "18px" }}
                              />
                            }
                            width="30px"
                            height="33px"
                          />
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content
                          className="chatDropDownContent"
                          align="end"
                        >
                          <DropdownMenu.Item
                            className="chatDropDownMenu"
                            onClick={() => {
                              setIsModalOpen({
                                open: true,
                                currentComponent: "addholiday",
                                isEdit: true,
                                id: data?.id,
                              });
                            }}
                          >
                            <Stack
                              flexDirection={"row"}
                              gap={1}
                              alignItems={"center"}
                            >
                              <AiOutlineEdit style={{ fontSize: "15px" }} />
                              <Box sx={{ fontSize: "13px" }}>{"Edit"}</Box>
                            </Stack>
                          </DropdownMenu.Item>
                          {/* <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <IoEyeOutline style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"View"}</Box>
                          </Stack>
                        </DropdownMenu.Item> */}

                          <DropdownMenu.Item
                            className="chatDropDownMenu"
                            onClick={() =>
                              setIsModalOpen({
                                open: true,
                                currentComponent: "delete",
                                head: "Delete Holiday",
                                para: "Are you sure you want to Delete this Holiday?",
                                id: data?.id,
                              })
                            }
                          >
                            <Stack
                              flexDirection={"row"}
                              gap={1}
                              alignItems={"center"}
                            >
                              <HiOutlineTrash style={{ fontSize: "15px" }} />
                              <Box sx={{ fontSize: "13px" }}>{"Delete"}</Box>
                            </Stack>
                          </DropdownMenu.Item>
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </TableCell>
                  </TableRow>
                ))}
              {holidaydata == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : holidaydata && holidaydata.length == 0 ? (
                <TableRowsLoader colNumber={6} rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {holidaydata != null && (
          <Pagination
            count={10}
            shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({
            open: false,
            currentComponent: "",
            id: "",
            isEdit: ""
          });
          getHolidayList();
        }}
        data={roledata}
        handelClick={changeStatus}
      />
    </>
  );
};

export default HolidayManagement;
