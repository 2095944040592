import React, { useEffect, useState } from "react";
import { BackButton, Button, Heading, Input } from "../../components/Common";
import {
  Grid,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Checkbox,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  PageList,
  RoleDetail,
  RoleCreate,
  RoleUpdate,
} from "../../Service/Role.service";
import { useFormik } from "formik";
import { roleAddInitialValues } from "../../helper/initialValues";
import { roleAddValidationSchema } from "../../helper/validation";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";

const CreateRole = () => {
  const [pageListData, setPageListData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckboxChange = (module, permission) => {
    setPermissions((prevPermissions) => {
      const newPermissions = [...prevPermissions];
      // newPermissions[moduleIndex] = {
      //   ...newPermissions[moduleIndex],
      //   [permission]: !newPermissions[moduleIndex][permission],
      // };
      newPermissions.map((item) => {
        if (item.pageId === module.id) {
          item[permission] = !item[permission];
        }
      });

      return newPermissions;
    });
  };

  console.log("dsdsdsdsdsdsdsd", permissions);

  const getPageList = async (id) => {
    const res = await PageList();
    if (res?.status) {
      setPageListData(res.data);

      setPermissions(
        res.data.map((item) => ({
          readP: false,
          writeP: false,
          deleteP: false,
          pageId: item.id,
          pageName: item.pageName,
        }))
      );
    } else {
      setPageListData([]);
    }
  };

  const formik = useFormik({
    initialValues: roleAddInitialValues,
    onSubmit: async (value) => {
      const payload = {
        roleName: value.roleName,
        permissions: permissions,
      };

      let res = isEdit
        ? await RoleUpdate(editId, payload)
        : await RoleCreate(payload);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        formik.resetForm();
        navigate("/role");
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: roleAddValidationSchema,
  });

  useEffect(() => {
    if (state && state?.isEdit && state?.id) {
      setIsEdit(true);
      getroleDetails(state.id);
      setEditId(state.id);
      getPageList(1);
    } else {
      setEditId(null);
      setIsEdit(false);
      getPageList();
    }
  }, [state]);

  const getroleDetails = async (id) => {
    let res = await RoleDetail(id);
    if (res?.status) {
      formik.setValues({
        roleName: res.data.roleName,
      });

      if (res?.data?.permission) {
        setPermissions(
          res.data.permission.map((item) => ({
            readP: item.readP == 1,
            writeP: item.writeP == 1,
            deleteP: item.deleteP == 1,
            pageId: item.pageId,
            id: item.id,
            pageName: item.pageName,
          }))
        );
      }
    }
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-start"}
        gap={1}
        alignItems={"center"}
        mb={2}
      >
        <BackButton />
        <Heading head={`${isEdit ? "Edit Role" : "Create Role"}`} />
      </Stack>
      <Box className="card_container">
        <Grid container spacing={2} maxWidth={{ sm: "900px" }}>
          <Grid item xs={12}>
            <Input
              labelinput={"Role Name"}
              name="roleName"
              maxLength={50}
              value={formik.values.roleName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.roleName && Boolean(formik.errors.roleName)}
              helperText={formik.touched.roleName && formik.errors.roleName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "13px", sm: "16px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Role Management"}
            </Typography>
            <Box>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Module Name</TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {"Read"}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {"Write"}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {"Delete"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageListData?.map((module, moduleIndex) => (
                      <TableRow
                        key={moduleIndex}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {module?.pageName}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          <Checkbox
                            checked={
                              permissions.filter(
                                (item) => item.pageId === module.id
                              )[0]?.readP
                            }
                            onChange={() =>
                              handleCheckboxChange(module, "readP")
                            }
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          <Checkbox
                            checked={
                              permissions.filter(
                                (item) => item.pageId === module.id
                              )[0]?.writeP
                            }
                            onChange={() =>
                              handleCheckboxChange(module, "writeP")
                            }
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          <Checkbox
                            checked={
                              permissions.filter(
                                (item) => item.pageId === module.id
                              )[0]?.deleteP
                            }
                            onChange={() =>
                              handleCheckboxChange(module, "deleteP")
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
        <Stack flexDirection={"row"} mt={2} gap={1}>
          <Button
            buttonName={"Cancel"}
            size="small"
            color="white"
            onClick={() => navigate("/role")}
          />
          <Button
            buttonName={`${isEdit ? "Update" : "Create"}`}
            size="small"
            onClick={formik.handleSubmit}
          />
        </Stack>
      </Box>
    </>
  );
};

export default CreateRole;
