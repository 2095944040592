import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import { Heading, Input, Modal } from "../../../components/Common";
import AddEmployeeHeading from "./AddEmployeeHeading";
import { yupResolver } from "@hookform/resolvers/yup";
import { persnalDatavalidationSchema } from "../../../helper/validation";
import CommonSelect from "../../../components/Common/Select";
import CommonInput from "../../../components/Common/Input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const OfficialDetails = ({ formik, onNext, onPrevious, isEdit, gotoBack }) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  return (
    <>
      <Stack mb={2}>
        {/* <AddEmployeeHeading isEdit={isEdit} /> */}
        <div>
          <Stack gap={2}>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <ArrowBackIcon
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "status",
                    para: "Are you sure you want to go back?",
                    head: "Warning",
                  });
                }}
                style={{
                  cursor: "pointer",
                  background: "#ffc107",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "4px",
                  fontSize: "25px",
                }}
              />{" "}
              <Heading head={isEdit ? "Edit Employee" : "Add Employee"} />
            </Stack>
          </Stack>
        </div>
      </Stack>
      <Stack className="Sideempdetails">
        <Typography variant="h6" className="personaldetailsHeading" mb={3}>
          Employment Details
        </Typography>

        {/* <Grid container spacing={2}>
          <Grid item xs={3} sm={3}>
            <CommonSelect
              selectList={[
                { id: "Married", name: "Married" },
                { id: "Unmarried", name: "Unmarried" },
              ]}
              labelinput="Marital Status"
              formik={formik}
              value={formik.values.maritalStatus}
              onChange={(e) =>
                formik.setFieldValue("maritalStatus", e.target.value)
              }
              name="maritalStatus"
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <CommonInput
              labelinput="Father's Name"
              formik={formik}
              name="fathersName"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <CommonInput
              labelinput="Father's Contact Number"
              formik={formik}
              name="fathersContact"
              maxLength={15}
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={2}>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Joining Date"
              formik={formik}
              name="joining_date"
              type="date"
              isDate={true}
            />
          </Grid>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Designation"
              formik={formik}
              name="designation"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Departments"
              formik={formik}
              name="departments"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Office Branch"
              formik={formik}
              name="branch"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Office Contact Number"
              formik={formik}
              name="contactOffice"
              maxLength={15}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CommonInput
              labelinput="Email ID (Office)"
              formik={formik}
              name="email"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CommonSelect
              selectList={[
                { id: 1, name: "Full-time" },
                { id: 2, name: "Part-time" },
                { id: 3, name: "Contract" },
              ]}
              labelinput="Employment Type"
              formik={formik}
              onChange={(e) =>
                formik.setFieldValue("employeementType", e.target.value)
              }
              name="employeementType"
              value={formik.values.employeementType}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CommonSelect
              selectList={[
                { id: 1, name: "Active" },
                { id: 0, name: "Inactive" },
              ]}
              labelinput="Employment Status"
              formik={formik}
              onChange={(e) =>
                formik.setFieldValue("status", e.target.value)
              }
              name="status"
              value={formik.values.status}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          className="personaldetailsHeading"
          mb={3}
          style={{
            marginTop: "2rem",
          }}
        >
          Family Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Father's Name"
              formik={formik}
              name="fathersName"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Father's Contact Number"
              formik={formik}
              name="fathersContact"
              maxLength={15}
            />
          </Grid>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Mother's Name"
              formik={formik}
              name="motherName"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Mother's Contact Number"
              formik={formik}
              name="motherContact"
              maxLength={15}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={() => onPrevious()}
            variant="outlined"
            color="secondary"
          >
            Previous
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={formik.handleSubmit}
          >
            Next
          </Button>
        </Grid>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={gotoBack}
      />
    </>
  );
};

export default OfficialDetails;
