import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Switch,
  Collapse,
  Typography,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { AiOutlineEdit } from "../../helper/Icons";
import {
  Button,
  Heading,
  Input,
  MenuButton,
  Modal,
} from "../../components/Common";
import { Pagination, Search } from "../../components/Common/Table";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { IoAdd } from "react-icons/io5";
import { MdMoreVert } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { VscFilter } from "react-icons/vsc";
import { Tab } from "bootstrap";
import {
  RoleList,
  RoleCreate,
  RoleUpdate,
  RoleDelete,
  RoleStatusChange,
} from "../../Service/Role.service";
import { addAdminValidationSchema } from "../../helper/validation";
import {
  roleInitialValues,
  filterRoleInitialValues,
} from "../../helper/initialValues";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useFormik } from "formik";
import moment from "moment";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";

const Role = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const [roleData, setRoleData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [search, setSearch] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const dispatch = useDispatch();

  const formik1 = useFormik({
    initialValues: filterRoleInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getRoleList();
    },
  });

  const getRoleList = async () => {
    setRoleData([]);
    let selectQry = `?page=${
      page + 1
    }&limit=${rowsPerPage}&search=${search}&status=${
      formik1.values.status
    }&role=${formik1.values.role}&sortBy=${sortConfig.key}&sortFlag=${
      sortConfig.direction
    }`;
    let res = await RoleList(selectQry);
    if (res?.status) {
      setRoleData(res.data);
      setTotalData(res.total);
    } else {
      setRoleData(null);
    }
  };

  useEffect(() => {
    getRoleList();
  }, [search, page, rowsPerPage, sortConfig]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await RoleStatusChange(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
        ? await RoleDelete(isModalOpen?.id)
        : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getRoleList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
  };
  const [status, setstatus] = useState("");

  const handleStatus = (event) => {
    setstatus(event.target.value);
  };
  return (
    <>
      <Stack gap={1}>
        <Heading head={"Role Management"} />
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
            <Button
              buttonName={"Create"}
              size="small"
              onClick={() => navigate("/create-role")}
            />
          </Stack>
        </Stack>

        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Role Name</TableCell>
                <TableCell>Create dDate</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleData.map((data, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{data.roleName}</TableCell>

                  <TableCell>
                    {moment(data?.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Switch
                      size="small"
                      checked={data?.status === 1 ? true : false}
                      disabled={formik1.values.status == 2}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({
                          id: data?.id,
                          open: true,
                          currentComponent: "status",
                          head: "Change Status",
                          para: "Are you sure you want to Change the Status?",
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className="chatDropDown">
                        <MenuButton
                          icon={
                            <MdMoreVert
                              style={{ color: "#000", fontSize: "18px" }}
                            />
                          }
                          width="30px"
                          height="33px"
                        />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content
                        className="chatDropDownContent"
                        align="end"
                      >
                        <DropdownMenu.Item
                          className="chatDropDownMenu"
                          onClick={() => {
                            navigate("/create-role", {
                              state: { id: data?.id, isEdit: true },
                            });
                          }}
                        >
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <AiOutlineEdit style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>Edit</Box>
                          </Stack>
                        </DropdownMenu.Item>

                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() =>
                              setIsModalOpen({
                                open: true,
                                currentComponent: "delete",
                                head: "Delete Role",
                                para: "Are you sure you want to Delete this Role?",
                                id: data?.id,
                              })
                            }
                          >
                            <HiOutlineTrash style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Delete"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              ))}
              {roleData == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : roleData && roleData.length == 0 ? (
                <TableRowsLoader colNumber={4} rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {roleData != null && (
          <Pagination
            count={10}
            shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={changeStatus}
      />
    </>
  );
};

export default Role;
