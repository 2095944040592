import React, { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Input,
  MenuButton,
  Modal,
} from "../../../src/components/Common";
import {
  Box,
  Collapse,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Pagination, Search } from "../../components/Common/Table";
import { HiEye, HiOutlineTrash } from "react-icons/hi";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from "react-icons/md";
import { BsEye } from "react-icons/bs";
import { VscFilter } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import {
  CompanyList,
  DeleteCompany,
  StatusChangeCompany,
} from "../../Service/Company.service";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import { filterCompanyInitialValues } from "../../helper/initialValues";
import { useFormik } from "formik";
import CommonSelect from "../../components/Common/Select";

const CompanyModule = () => {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setstatus] = useState("");

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCompanyList = async () => {
    setCompanyData([]);
    const selectQry = `?search=${search}&status=${formik1.values.status}&page=${page}&limit=${rowsPerPage}`;
    const res = await CompanyList(selectQry);

    if (res?.status) {
      setCompanyData(res.data);
      setTotalData(res.total);
    } else {
      setCompanyData(null);
    }
  };

  useEffect(() => {
    getCompanyList();
  }, [search, page, rowsPerPage]);

  const formik1 = useFormik({
    initialValues: filterCompanyInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getCompanyList();
    },
  });
  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await StatusChangeCompany(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
        ? await DeleteCompany(isModalOpen?.id)
        : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getCompanyList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
    formik1.resetForm();
    getCompanyList();
  };

  const handleStatus = (event) => {
    setstatus(event.target.value);
  };
  return (
    <>
      <Stack gap={1}>
        <Heading head={"Companies"} />

        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button
              buttonName={"Add"}
              size="small"
              onClick={() => navigate("/add-company")}
            />
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              variant="contained"
              // onClick={toggleDrawer}
              onClick={handleFilterClick}
              sx={{ mt: 2 }}
            />
            {/* <EmployeeFilter
              openDrawer={openDrawer}
              toggleDrawer={toggleDrawer}
            /> */}
          </Stack>
        </Stack>
        <Collapse Collapse in={showFilter}>
          <Stack
            mb={"12px"}
            sx={{ background: "#151515" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1}
            alignItems={"end"}
          >
            <Grid className="filterinput">
              <Typography className="inputfont">{"Status"}:</Typography>
              <CommonSelect
                name="status"
                value={formik1.values.status}
                formik={formik1}
                selectList={[
                  { id: 1, name: "Active" },
                  { id: 0, name: "Inactive" },
                  { id: 2, name: "Delete" },
                ]}
                onChange={(e) =>
                  formik1.setFieldValue("status", e.target.value)
                }
                selectHead="Select Status"
              />
            </Grid>
            <Box>
              <Button buttonName={"Filter"} onClick={formik1.handleSubmit} />
            </Box>
            <Box>
              <Button buttonName={"Clear"} onClick={() => clearFilter()} />
            </Box>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Logo</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Company Name</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Email</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Contact</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Website</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Status</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"end"}
                  >
                    <div>Action</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData?.map((data) => (
                <TableRow
                  key={data.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    navigate("/company-details", {
                      state: { id: data?.id, isEdit: true },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    <img
                      src={data.logo}
                      alt=""
                      srcset=""
                      style={{ width: "100px", objectFit: "contain" }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {data.name}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.email}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.mobile}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.website}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    <Switch
                      size="small"
                      checked={data?.status === 1 ? true : false}
                      disabled={data?.status == 2 ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({
                          id: data?.id,
                          open: true,
                          currentComponent: "status",
                          head: "Change Status",
                          para: "Are you sure you want to Change the Status?",
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    sx={{ marginLeft: "10px" }}
                  >
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className="chatDropDown">
                        <MenuButton
                          icon={
                            <MdMoreVert
                              style={{ color: "#000", fontSize: "18px" }}
                            />
                          }
                          width="30px"
                          height="33px"
                        />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content
                        className="chatDropDownContent"
                        align="end"
                      >
                        <DropdownMenu.Item
                          className="chatDropDownMenu"
                          onClick={() => {
                            navigate("/company-details", {
                              state: { id: data?.id, isEdit: true },
                            });
                          }}
                        >
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <HiEye style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"View"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          className="chatDropDownMenu"
                          onClick={() => {
                            navigate("/add-company", {
                              state: { id: data?.id, isEdit: true },
                            });
                          }}
                        >
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <AiOutlineEdit style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>Edit</Box>
                          </Stack>
                        </DropdownMenu.Item>

                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() =>
                              setIsModalOpen({
                                open: true,
                                currentComponent: "delete",
                                head: "Delete Company",
                                para: "Are you sure you want to Delete this Company?",
                                id: data?.id,
                              })
                            }
                          >
                            <HiOutlineTrash style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Delete"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              ))}
              {companyData == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : companyData && companyData.length == 0 ? (
                <TableRowsLoader colNumber={7} rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {companyData != null && (
          <Pagination
            count={10}
            shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={changeStatus}
      />
    </>
  );
};

export default CompanyModule;
