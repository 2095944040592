import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Paper,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button } from "../../components/Common";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { holidayAddInitialValues } from "../../helper/initialValues";
import { holidayAddValidationSchema } from "../../helper/validation";
import { useDispatch } from "react-redux";
import {
  CreateHoliday,
  HolidayDetail,
  UpdateHoliday,
} from "../../Service/Holiday.service";
import { CompanyDropdown } from "../../Service/Company.service";
import { notificationSuccess } from "../../state/Action/NotificationAction";

const ViewHoliday_model = ({ handleClose, modalOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState([]);

  const formik = useFormik({
    initialValues: holidayAddInitialValues,
    onSubmit: async (value) => {
      const payload = {
        companyId: value.companyId,
        title: value.title,
        date: value.date,
        day: value.day,
        description: value.description,
        type: value.type,
      };

      let res = modalOpen.isEdit
        ? await UpdateHoliday(modalOpen.id, payload)
        : await CreateHoliday(payload);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        formik.resetForm();
        navigate("/holiday");
        handleClose();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: holidayAddValidationSchema,
  });

  const getDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (modalOpen.isEdit) {
      getHolidayDetails(modalOpen.id);
    }
  }, [modalOpen.isEdit]);

  const getHolidayDetails = async (id) => {
    let res = await HolidayDetail(id);
    if (res?.status) {
      formik.setValues({
        companyId: res.data.companyId,
        title: res.data.title,
        date: res.data.date,
        day: res.data.day,
        description: res.data.description,
        type: res.data.type,
      });
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Holiday Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Holiday Title</Typography>
            <Typography variant="body1">{formik.values.title}</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Company Name</Typography>
            <Typography variant="body1">
              {companyData.find((company) => company.id === formik.values.companyId)?.name || "N/A"}
            </Typography>
          </Grid>
          <>
            <Grid item xs={12} sm={6}>
              <>
                <Typography variant="h6">Date</Typography>
                <Typography variant="body1">{formik.values.date}</Typography>
              </>
            </Grid>
            <Grid item xs={12} sm={6}>
              <>
                <Typography variant="h6">Day</Typography>
                <Typography variant="body1">{formik.values.day}</Typography>
              </>
            </Grid>
          </>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Description</Typography>
            <Typography variant="body1">{formik.values.description}</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Type</Typography>
            <Typography variant="body1">
              {formik.values.type === "1" ? "Public" : "Composite"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Close"}
          size="small"
          color="white"
          onClick={handleClose}
        />
      </DialogActions>
    </>
  );
};

export default ViewHoliday_model;
