import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "../../../components/Common";
const AddSalaryModal = ({ handleClose, status, handleChange }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Add Salary
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Employee Name"}
            </Typography>

            <FormControl fullWidth variant="outlined">
              <Select
                labelId="status-label"
                id="status"
                value={status}
                onChange={handleChange}
              >
                <MenuItem value="0">Sumit Kumar</MenuItem>
                <MenuItem value="1">Rajdeep Kumar</MenuItem>
                <MenuItem value="2">Deep Kumar</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Basic Salary"}
              type="text"
              name="startdate"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Allowances"}
              type="text"
              name="startdate"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"deductions"}
              type="text"
              name="enddate"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"tax_details"}
              type="text"
              name="enddate"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"effective_date"}
              type="text"
              name="enddate"
              maxLength={50}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName="Create" size="small" />
      </DialogActions>
    </>
  );
};

export default AddSalaryModal;
