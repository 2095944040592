import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { BsEye } from "react-icons/bs";
import { HiOutlineTrash } from "react-icons/hi";
import { Button } from "../../../components/Common";
import { useFormik } from "formik";
import { addDocumentValidationSchema } from "../../../helper/validation";
import { addDocumentInitialValues } from "../../../helper/initialValues";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AddDocument,
  DocumentDetail,
  UpdateDocument,
  deleteSpecificDocument,
} from "../../../Service/Document.service";
import { Close, Delete } from "@mui/icons-material";
import { MdDelete } from "react-icons/md";
import aadharcard1 from "../../../assets/images/aadhaar-card.png";
import pancard from "../../../assets/images/pan-card.png";
import Modal1 from "../../../components/Common/Modal";

const EmployeeDocument = ({ employeeId }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    if (employeeId) {
      getDocumentDetails(employeeId);
    }
  }, [employeeId]);

  const getDocumentDetails = async (id) => {
    let res = await DocumentDetail(id);
    if (res?.status) {
      formik.setValues({
        ...formik.values,
        passportName: res.data.passport,
        educationsDocName: res.data.educationsDoc,
        preEmpDocName: res.data.preEmpDoc,
        joiningDocName: res.data.joiningDoc,
        panCardFrontlink: res.data.panCard,
        panCardBacklink: res.data.panCard1,
        adharCardFrontlink: res.data.adharCard,
        adharCardBacklink: res.data.adharCard1,
      });
      setImages((prevImages) => ({
        ...prevImages,
        adharCardFront: res.data.adharCard,
        adharCardBack: res.data.adharCard1,
        panCardFront: res.data.panCard,
        panCardBack: res.data.panCard1,
        passport: res.data.passport,
        educationsDoc: res.data.educationsDoc,
        preEmpDoc: res.data.preEmpDoc,
        joiningDoc: res.data.joiningDoc,
      }));

      setLoading((prevLoading) => ({
        ...prevLoading,
        adharCardFront: false,
        adharCardBack: false,
        panCardFront: false,
        panCardBack: false,
        passport: false,
        educationsDoc: false,
        preEmpDoc: false,
        joiningDoc: false,
      }));
    }
  };

  const deleteDocument = async () => {
    let res = await deleteSpecificDocument(employeeId, isModalOpen.type);
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getDocumentDetails(employeeId);
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
      });
    } else {
      if (isModalOpen.type === 1) {
        formik.setFieldValue("adharCardBack", null);
        formik.setFieldValue("adharCardFront", null);

        setLoading((prevLoading) => ({
          ...prevLoading,
          adharCardBack: false,
          adharCardFront: false,
        }));
        setImages((prevImages) => ({
          ...prevImages,
          adharCardBack: null,
          adharCardFront: null,
        }));
      } else if (isModalOpen.type === 2) {
        formik.setFieldValue("panCardBack", null);
        formik.setFieldValue("panCardFront", null);
        setLoading((prevLoading) => ({
          ...prevLoading,
          panCardBack: false,
          panCardFront: false,
        }));
        setImages((prevImages) => ({
          ...prevImages,
          panCardBack: null,
          panCardFront: null,
        }));
      } else if (isModalOpen.type === 3) {
        formik.setFieldValue("passport", null);

        setLoading((prevLoading) => ({
          ...prevLoading,
          passport: false,
        }));

        setImages((prevImages) => ({
          ...prevImages,
          passport: null,
        }));
      } else if (isModalOpen.type === 4) {
        formik.setFieldValue("educationsDoc", null);

        setLoading((prevLoading) => ({
          ...prevLoading,
          educationsDoc: false,
        }));

        setImages((prevImages) => ({
          ...prevImages,
          educationsDoc: null,
        }));
      } else if (isModalOpen.type === 5) {
        formik.setFieldValue("preEmpDoc", null);
        setLoading((prevLoading) => ({
          ...prevLoading,
          preEmpDoc: false,
        }));
        setImages((prevImages) => ({
          ...prevImages,
          preEmpDoc: null,
        }));
      } else if (isModalOpen.type === 6) {
        formik.setFieldValue("joiningDoc", null);

        setLoading((prevLoading) => ({
          ...prevLoading,
          joiningDoc: false,
        }));

        setImages((prevImages) => ({
          ...prevImages,
          joiningDoc: null,
        }));
      }
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
      });
    }
  };

  const formik = useFormik({
    initialValues: addDocumentInitialValues,
    // validationSchema: addDocumentValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("employeeId", employeeId);

      formData.append("adharCard", values.adharCardFront);
      formData.append("adharCard1", values.adharCardBack);
      formData.append("panCard", values.panCardFront);
      formData.append("panCard1", values.panCardBack);
      formData.append("passport", values.passport);
      formData.append("educationsDoc", values.educationsDoc);
      formData.append("preEmpDoc", values.preEmpDoc);
      formData.append("joiningDoc", values.joiningDoc);

      let res = await AddDocument(formData);

      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        getDocumentDetails(employeeId);
        formik.resetForm();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
  });

  const handleOpen = (imgsrc) => {
    setSelectedImage(imgsrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    formik.resetForm();
  };

  const handleFileChange = (event, name) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue(name, file);
  };

  const [loading, setLoading] = useState({
    adharCardFront: false,
    adharCardBack: false,
    panCardFront: false,
    panCardBack: false,
    joiningDoc: false,
    passport: false,
    educationsDoc: false,
    preEmpDoc: false,
  });

  const [images, setImages] = useState({
    adharCardFront: null,
    adharCardBack: null,
    panCardFront: null,
    panCardBack: null,
    joiningDoc: null,
    passport: null,
    educationsDoc: null,
    preEmpDoc: null,
    passportName: null,
    educationsDocName: null,
    preEmpDocName: null,
    joiningDocName: null,
  });

  const handleFileChange1 = (event, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImages((prevImages) => ({
          ...prevImages,
          [fieldName]: e.target.result,
        }));
        setLoading((prevLoading) => ({
          ...prevLoading,
          [fieldName]: false,
        }));
      };
      reader.readAsDataURL(file);
      setLoading((prevLoading) => ({
        ...prevLoading,
        [fieldName]: true,
      }));
      if (fieldName == "passport") {
        formik.setFieldValue("passportName", file.name);
      } else if (fieldName == "educationsDoc") {
        formik.setFieldValue("educationsDocName", file.name);
      } else if (fieldName == "preEmpDoc") {
        formik.setFieldValue("preEmpDocName", file.name);
      } else if (fieldName == "joiningDoc") {
        formik.setFieldValue("joiningDocName", file.name);
      }
      formik.setFieldValue(fieldName, file);
    }
  };

  console.log("AddDocument", formik.values);
  return (
    <>
      <Stack gap={2}>
        <Stack className="Sideempdetails">
          <Box className="mainH1" sx={{ marginBottom: "10px" }}>
            Employee Documents
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell>Images (Front & Back)</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Aadhar Card</TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Front Image */}
                    {loading.adharCardFront || !images.adharCardFront ? (
                      <>
                        <img
                          src={aadharcard1}
                          width={150}
                          height={150}
                          onClick={() =>
                            document
                              .getElementById("adharCardFrontInput")
                              .click()
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    ) : (
                      <img
                        src={images.adharCardFront}
                        alt="Aadhar Card Front"
                        style={{
                          width: "150px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpen(images.adharCardFront)}
                      />
                    )}
                    <input
                      type="file"
                      id="adharCardFrontInput"
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleFileChange1(event, "adharCardFront")
                      }
                    />

                    {/* Back Image */}
                    {loading.adharCardBack || !images.adharCardBack ? (
                      // <Skeleton
                      //   variant="rectangular"
                      //   width={100}
                      //   height={100}
                      //   onClick={() =>
                      //     document.getElementById("adharCardBackInput").click()
                      //   }
                      //   style={{ cursor: "pointer" }}
                      // />
                      <img
                        src={aadharcard1}
                        width={150}
                        height={150}
                        onClick={() =>
                          document.getElementById("adharCardBackInput").click()
                        }
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={images.adharCardBack}
                        alt="Aadhar Card Back"
                        style={{
                          width: "150px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpen(images.adharCardBack)}
                      />
                    )}
                    <input
                      type="file"
                      id="adharCardBackInput"
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleFileChange1(event, "adharCardBack")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {/* <Button
                      buttonName={"save"}
                      color="white"
                      style={{ marginRight: "10px" }}
                    /> */}
                    {formik.values.adharCardFrontlink ||
                    formik.values.adharCardBacklink ||
                    formik?.values?.adharCardFront ||
                    formik?.values?.adharCardBack ? (
                      <Button
                        buttonName={<MdDelete />}
                        color="red"
                        onClick={() => {
                          setIsModalOpen({
                            id: employeeId,
                            open: true,
                            type: 1,
                            currentComponent: "delete",
                            head: "Delete Document",
                            para: "Are you sure you want to Delete this Document?",
                          });
                        }}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>PAN Card</TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Front Image */}
                    {loading.panCardFront || !images.panCardFront ? (
                      // <Skeleton
                      //   variant="rectangular"
                      //   width={100}
                      //   height={100}
                      //   onClick={() =>
                      //     document.getElementById("panCardFrontInput").click()
                      //   }
                      //   style={{ cursor: "pointer" }}
                      // />
                      <img
                        src={pancard}
                        width={150}
                        height={150}
                        onClick={() =>
                          document.getElementById("panCardFrontInput").click()
                        }
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={images.panCardFront}
                        alt="PAN Card Front"
                        style={{
                          width: "150px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpen(images.panCardFront)}
                      />
                    )}
                    <input
                      type="file"
                      id="panCardFrontInput"
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleFileChange1(event, "panCardFront")
                      }
                    />

                    {/* Back Image */}
                    {loading.panCardBack || !images.panCardBack ? (
                      // <Skeleton
                      //   variant="rectangular"
                      //   width={100}
                      //   height={100}
                      //   onClick={() =>
                      //     document.getElementById("panCardBackInput").click()
                      //   }
                      //   style={{ cursor: "pointer" }}
                      // />
                      <img
                        src={pancard}
                        width={150}
                        height={150}
                        onClick={() =>
                          document.getElementById("panCardBackInput").click()
                        }
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={images.panCardBack}
                        alt="PAN Card Back"
                        style={{
                          width: "150px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpen(images.panCardBack)}
                      />
                    )}
                    <input
                      type="file"
                      id="panCardBackInput"
                      style={{ display: "none" }}
                      onChange={(event) =>
                        handleFileChange1(event, "panCardBack")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {/* <Button
                      buttonName={"save"}
                      color="white"
                      style={{ marginRight: "10px" }}
                    /> */}
                    {formik.values.panCardFrontlink ||
                    formik.values.panCardBacklink ||
                    formik?.values?.panCardFront ||
                    formik?.values?.panCardBack ? (
                      <Button
                        buttonName={<MdDelete />}
                        color="red"
                        onClick={() => {
                          setIsModalOpen({
                            id: employeeId,
                            open: true,
                            type: 2,
                            currentComponent: "delete",
                            head: "Delete Document",
                            para: "Are you sure you want to Delete this Document?",
                          });
                        }}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack className="Sideempdetails">
          <Box className="mainH1" sx={{ marginBottom: "10px" }}>
            Professional Documents
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell>Uploaded Document</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Passport</TableCell>
                  <TableCell>
                    <input
                      type="file"
                      name="passport"
                      onChange={(event) => handleFileChange1(event, "passport")}
                    />
                  </TableCell>
                  <TableCell>
                    {images.passport && (
                      // <img
                      //   src={images.passport}
                      //   alt="passport"
                      //   style={{
                      //     width: "100px",
                      //     height: "auto",
                      //     cursor: "pointer",
                      //   }}
                      //   onClick={() => handleOpen(images.passport)}
                      // />
                      <Typography
                        onClick={() => {
                          window.open(images.passport, "_blank");
                          formik.values.passportName
                            .split(".")
                            .pop()
                            .toLowerCase() == ("png" || "jpg" || "jpeg")
                            ? handleOpen(images.passport)
                            : window.open(images.passport, "_blank");
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {formik.values.passportName?.split("/").pop()}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {/* <BsEye
                      style={{
                        fontSize: "15px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpen(formik.values.passport)}
                    /> */}
                    {(formik.values.passportlink || formik.values.passport) && (
                      <HiOutlineTrash
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() => {
                          setIsModalOpen({
                            id: employeeId,
                            open: true,
                            type: 3,
                            currentComponent: "delete",
                            head: "Delete Document",
                            para: "Are you sure you want to Delete this Document?",
                          });
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Education Document</TableCell>
                  <TableCell>
                    <input
                      type="file"
                      name="educationsDoc"
                      onChange={(event) =>
                        handleFileChange1(event, "educationsDoc")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {images.educationsDoc && (
                      // <img
                      //   src={images.educationsDoc}
                      //   alt="education doc"
                      //   style={{
                      //     width: "100px",
                      //     height: "auto",
                      //     cursor: "pointer",
                      //   }}
                      //   onClick={() => handleOpen(images.educationsDoc)}
                      // />
                      <Typography
                        onClick={() => {
                          formik.values.educationsDocName
                            .split(".")
                            .pop()
                            .toLowerCase() == ("png" || "jpg" || "jpeg")
                            ? handleOpen(images.educationsDoc)
                            : window.open(images.educationsDoc, "_blank");
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {formik.values.educationsDocName?.split("/").pop()}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {/* <BsEye
                      style={{
                        fontSize: "15px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpen(images.educationsDoc)}
                    /> */}
                    {(formik.values.educationsDoclink ||
                      formik.values.educationsDoc) && (
                      <HiOutlineTrash
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() => {
                          setIsModalOpen({
                            id: employeeId,
                            open: true,
                            type: 4,
                            currentComponent: "delete",
                            head: "Delete Document",
                            para: "Are you sure you want to Delete this Document?",
                          });
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Previous Employment Document</TableCell>
                  <TableCell>
                    <input
                      type="file"
                      name="preEmpDoc"
                      onChange={(event) =>
                        handleFileChange1(event, "preEmpDoc")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {images.preEmpDoc && (
                      <Typography
                        onClick={() => {
                          formik.values.preEmpDocName
                            .split(".")
                            .pop()
                            .toLowerCase() == ("png" || "jpg" || "jpeg")
                            ? handleOpen(images.preEmpDoc)
                            : window.open(images.preEmpDoc, "_blank");
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {formik.values.preEmpDocName?.split("/").pop()}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {/* <BsEye
                      style={{
                        fontSize: "15px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpen(images.preEmpDoc)}
                    /> */}
                    {(formik.values.preEmpDoclink ||
                      formik.values.preEmpDoc) && (
                      <HiOutlineTrash
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() => {
                          setIsModalOpen({
                            id: employeeId,
                            open: true,
                            type: 5,
                            currentComponent: "delete",
                            head: "Delete Document",
                            para: "Are you sure you want to Delete this Document?",
                          });
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Joining Document</TableCell>
                  <TableCell>
                    <input
                      type="file"
                      name="joiningDoc"
                      onChange={(event) =>
                        handleFileChange1(event, "joiningDoc")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {images.joiningDoc && (
                      // <img
                      //   src={images.joiningDoc}
                      //   alt="Joining Document"
                      //   style={{
                      //     width: "100px",
                      //     height: "auto",
                      //     cursor: "pointer",
                      //   }}
                      //   onClick={() => {
                      //     window.open(images.joiningDoc, "_blank");
                      //   }}
                      // />
                      <Typography
                        onClick={() => {
                          formik.values.joiningDocName
                            .split(".")
                            .pop()
                            .toLowerCase() == ("png" || "jpg" || "jpeg")
                            ? handleOpen(images.joiningDoc)
                            : window.open(images.joiningDoc, "_blank");
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        {formik.values.joiningDocName?.split("/").pop()}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {/* <BsEye
                      style={{
                        fontSize: "15px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpen(images.joiningDoc)}
                    
                    /> */}

                    {formik.values.joiningDoclink ||
                      (formik.values.joiningDoc && (
                        <HiOutlineTrash
                          style={{ fontSize: "15px", cursor: "pointer" }}
                          onClick={() => {
                            setIsModalOpen({
                              id: employeeId,
                              open: true,
                              type: 6,
                              currentComponent: "delete",
                              head: "Delete Document",
                              para: "Are you sure you want to Delete this Document?",
                            });
                          }}
                        />
                      ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ paddingTop: "20px" }}
        >
          <Button buttonName="Cancel" onClick={handleCancel} />
          <Button buttonName="Submit" onClick={formik.handleSubmit} />
        </Stack>
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            {/* <IconButton
              edge="end"
              color="inherit"
              // onClick={handleDelete}
              aria-label="delete"
              sx={{ position: "absolute", top: 8, right: 60 }}
            >
              <Delete />
            </IconButton> */}
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ position: "absolute", top: 8, right: 25 }}
            >
              <Close />
            </IconButton>
          </div>
          <Box sx={{ maxWidth: "100%", margin: "auto", marginTop: "5%" }}>
            <img
              src={selectedImage}
              alt="Preview"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "500px",
                display: "block",
                margin: "auto",
              }}
            />
          </Box>
        </Box>
      </Modal>
      <Modal1
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={() => {
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          });
          // formik.resetForm();
        }}
        handelClick={deleteDocument}
        formik={formik}
      />
    </>
  );
};

export default EmployeeDocument;
