import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import {
  BackButton,
  FileUpload,
  Heading,
  Input,
} from "../../components/Common";
import { useFormik } from "formik";
import { AdminCreate, AdminDetail, AdminUpdate } from '../../Service/Admin.service';
import { addAdminValidationSchema } from '../../helper/validation';
import { addAdminInitialValues } from '../../helper/initialValues';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import { RoleDropdown } from "../../Service/Role.service";
import { useNavigate, useLocation } from "react-router-dom";
import { CompanyDropdown } from "../../Service/Company.service";
import CommonSelect from "../../components/Common/Select";

const AddUserModel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [roledata, setRoleData] = useState([]);
  const [files, setFiles] = useState([]);
  const { state } = useLocation();
  const [companyData, setCompanyData] = useState([]);

  const getCompanyDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  const getDropdownData = async () => {
    let res = await RoleDropdown();
    if (res?.status) {
      setRoleData(res.data);
    } else {
      setRoleData([]);
    }
  }

  useEffect(() => {
    getDropdownData();
    getCompanyDropdownData();
  }, []);
  const formik = useFormik({

    initialValues: addAdminInitialValues,
    validationSchema: addAdminValidationSchema,
    onSubmit: async (values) => {
      // Create FormData object
      const formData = new FormData();
      if (files.length == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: "Please upload an image."
        }));
        return;
      }
      Object.entries(values).forEach(([key, value]) => {
        if (key === 'image' && value == true) {
          formData.append("profile", files[0]);
        }
        else if (value) {
          formData.append(key, value); // Append other form data
        }
      });

      // Determine if it's an update or create operation
      let res;
      if (editId) {
        res = await AdminUpdate(editId, formData);
      } else {
        res = await AdminCreate(formData);
      }

      // Handle the response
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }));
        navigate('/user');
        formik.resetForm();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.'
        }));
      }
    },
  });

  useEffect(() => {
    if (state && state?.isEdit && state?.id) {
      setIsEdit(true);
      getAdminDetails(state.id);
      setEditId(state.id);
    } else {
      setEditId(null);
      setIsEdit(false);
    }
  }, [state]);

  const getAdminDetails = async (id) => {
    let res = await AdminDetail(id);
    if (res?.status) {
      formik.setValues({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        role: res.data.role.id,
        mobile: res.data.mobile,
        companyId: res.data.companyId,
      });
      setFiles([{ image: res.data.profile || null }]);
    }
  };

  console.log("formikvalues", formik.values);

  return (
    <>
      <Stack mb={2}>
        <div>
          <Stack gap={2}>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <BackButton />
              <Heading head={isEdit ? "Edit User" : "Add User"} />
            </Stack>
          </Stack>
        </div>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack className="Sideempdetails">
          {/* Form Fields */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography fontSize={{ xs: "13px", sm: "14px" }} fontWeight={500} mb={"2px"}>
                Image
              </Typography>
              <FileUpload
                files={files}
                setFiles={setFiles}
                singleImage={true}
                name="image"
                formik={formik}
              />
              {formik.touched.image && formik.errors.image && (
                <Typography color="error">{formik.errors.image}</Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Input
                name="firstName"
                variant="outlined"
                fullWidth
                labelinput="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Input
                name="lastName"
                variant="outlined"
                fullWidth
                labelinput="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Input
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                labelinput="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CommonSelect
                labelinput="Company"
                selectList={companyData}
                value={formik.values.companyId}
                onChange={(e) => formik.setFieldValue("companyId", e.target.value)}
                formik={formik}
                name="companyId"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <Typography fontSize={{ sx: "10px", xs: "13px" }} fontWeight={500} mb={'2px'}>
                  Select Role
                </Typography>
                <Select
                  name="role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <em>Select a role</em>
                  </MenuItem>
                  {roledata.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.roleName}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.role && formik.errors.role && (
                  <Typography fontSize={'10px'} color={'#D32F2F'}>{formik.errors.role}</Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Input
                name="mobile"
                variant="outlined"
                fullWidth
                labelinput="Mobile Number"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>

          </Grid>
        </Stack>

        {/* Submit Button */}
        <Grid container justifyContent="flex-end" spacing={2} mt={3}>
          <Grid item>
            <Button variant="outlined" onClick={() => formik.resetForm()}>
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              {isEdit ? "Update" : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddUserModel;
