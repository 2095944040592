import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, FileUpload, Input } from "../../../components/Common";

const AddAdmin = ({ handleClose, modalOpen, files, setFiles, handleChange }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Create Admin
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <Typography
              fontSize={{ xs: "13px", sm: "16px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Image"}
            </Typography>
            <FileUpload files={files} setFiles={setFiles} singleImage={true} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"FirstName"} name="firstName" maxLength={50} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"LastName"} name="lastName" maxLength={50} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Email"}
              name="email"
              disabled={modalOpen.isEdit}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography fontSize={{ sx: "10px", xs: "13px" }} fontWeight={500} mb={'2px'}>Select Role</Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Role"
                onChange={handleChange}
              >
                <MenuItem value={10}>Admin</MenuItem>
                <MenuItem value={20}>Super Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"mobileNumber"}
              name="mobileNumber"
              maxLength={12}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Address Line 1"}
              name="addressLine1"
              maxLength={100}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Address Line 2"}
              name="addressLine2"
              maxLength={100}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Input labelinput={"Country"} name="country" maxLength={30} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input labelinput={"State"} name="state" maxLength={30} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input labelinput={"City"} name="city" maxLength={30} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input labelinput={"ZipCode"} name="zipCode" maxLength={7} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName="Create" size="small" />
      </DialogActions>
    </>
  );
};

export default AddAdmin;
