import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, Input } from '../../Common'
import CommonInput from '../Input'

const ChangePassword = ({ handleClose, modalOpen, formik }) => {

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {"ChangePassword"}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} maxWidth={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <CommonInput
                            labelinput={'Old Password'}
                            passwordinput
                            name='oldPassword'
                            formik={formik}
                            autoComplete="new-password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput
                            labelinput={'New Password'}
                            passwordinput
                            name='newPassword'
                            formik={formik}
                            autoComplete="new-password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput
                            labelinput={'Confirm Password'}
                            passwordinput
                            name='confirmPassword'
                            formik={formik}
                            autoComplete="new-password"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={'Save'} size='small' onClick={formik.handleSubmit} />
            </DialogActions>
        </>
    )
}

export default ChangePassword