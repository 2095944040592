import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const TableRowsLoader = ({ rowsNum, colNumber }) => {
  colNumber = colNumber || 6;
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(colNumber)].map((col, index) => (
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" width={210} height={20} />
        </TableCell>
      ))}
      {/* // <TableCell>
      //   <Skeleton animation="wave" variant="text" width={210} height={20} />
      // </TableCell>
      // <TableCell>
      //   <Skeleton animation="wave" variant="text" width={210} height={20} />
      // </TableCell>
      // <TableCell>
      //   <Skeleton animation="wave" variant="text" width={210} height={20} />
      // </TableCell>
      // <TableCell>
      //   <Skeleton animation="wave" variant="text" width={210} height={20} />
      // </TableCell>
      // <TableCell>
      //   <Skeleton animation="wave" variant="text" width={210} height={20} />
      // </TableCell> */}
    </TableRow>
  ));
};

export default TableRowsLoader