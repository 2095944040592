import React, { useState, useEffect } from "react";
import OfficialDetails from "./OfficialDetails";
import EmployeeDataTab from "./EmployeeDataTab";
import IdentiDetailTab from "./IdentiDetailTab";
import { EduDetails } from "./EduDetails";
import BankDetailsAdd from "./BankDetailsAdd";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { addEmployeeInitialValues } from "../../../helper/initialValues";
import {
  BankValidationSchema,
  EducationValidationSchema,
  IdentificationValidationSchema,
  addEmployeeValidationSchema,
  employeeDatavalidationSchema,
  persnalDatavalidationSchema,
} from "../../../helper/validation";
import { useFormik } from "formik";
import {
  CreateEmployee,
  EmployeeDetail,
  UpdateEmployee,
} from "../../../Service/Employee.service";
import moment from "moment";

const FormContainer = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [files, setFiles] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    console.log("state", state);
    if (state && state?.id) {
      setIsEdit(true);
      getEmployeeDetails(state.id);
      setEditId(state.id);
      setCurrentTab(state?.currentTab || 0);
    } else {
      setEditId(null);
      setIsEdit(false);
    }
  }, [state]);

  const getEmployeeDetails = async (id) => {
    let res = await EmployeeDetail(id);
    if (res?.status) {
      formik.setValues({
        companyId: res.data.companyId || "",
        salutation: res.data.salutation || "",
        firstName: res.data.firstName || "",
        middleName: res.data.middleName || "",
        lastName: res.data.lastName || "",
        DOB: res.data.DOB
          ? moment(res.data.DOB).format("YYYY-MM-DD")
          : null || "",
        gender: res.data.gender || "",
        contact: res.data.contact || "",
        email: res.data.email || "",
        designation: res.data.designation || "",
        fathersName: res.data.fathersName || "",
        fathersContact: res.data.fathersContact || "",
        maritalStatus: res.data.maritalStatus || "",
        panNumber: res.data.panNumber || "",
        adharNumber: res.data.adharNumber || "",
        passportNumber: res.data.passportNumber || "",
        passportValidupto: res.data.passportValidupto
          ? moment(res.data.passportValidupto).format("YYYY-MM-DD")
          : null || "",
        departments: res.data.departments || "",
        branch: res.data.branch || "",
        joining_date: res.data.joining_date
          ? moment(res.data.joining_date).format("YYYY-MM-DD")
          : null || "",
        nameAsPerBank: res.data.nameAsPerBank || "",
        bankName: res.data.bankName || "",
        bankBranch: res.data.bankBranch || "",
        accountNumber: res.data.accountNumber || "",
        IFSCNumber: res.data.IFSCNumber || "",
        upiId: res.data.upiId || "",
        employeementType: res.data.employeementType || "",
        status: res.data.status,
        educationLevel: res?.data?.educationLevel || "",
        course: res?.data?.course || "",
        specification: res?.data?.specification || "",
        startYear: res?.data?.startYear
          ? moment(res?.data?.startYear).format("YYYY-MM")
          : null || "",
        passoutYear: res?.data?.passoutYear
          ? moment(res?.data?.passoutYear).format("YYYY-MM")
          : null || "",
        CGPA: res?.data?.CGPA || "",
        deduction: res?.data?.deduction || "",
        basicSalary: res?.data?.basicSalary || "",
        empCode: res?.data?.empCode || "",
        altContact: res?.data?.altContact || "",
        bloodGroup: res?.data?.bloodGroup || "",
        perosnalEmail: res?.data?.perosnalEmail || "",
        spouseName: res?.data?.spouseName || "",
        emergencyContact: res?.data?.emergencyContact || "",
        motherName: res?.data?.motherName || "",
        motherContact: res?.data?.motherContact || "",
        spouseContact: res?.data?.spouseContact || "",
        id: res.data.id || "",
        contactOffice: res.data.contactOffice || "",
        pLine1: res?.data?.pLine1 || "",
        pLine2: res?.data?.pLine2 || "",
        pCity: res?.data?.pCity || "",
        pState: res?.data?.pState || "",
        pZipcode: res?.data?.pZipcode || "",
        cLine1: res?.data?.cLine1 || "",
        cLine2: res?.data?.cLine2 || "",
        cCity: res?.data?.cCity || "",
        cState: res?.data?.cState || "",
        cZipcode: res?.data?.cZipcode || "",
      });
      setFiles(res.data.logo || null);
    }
  };

  const formik = useFormik({
    initialValues: addEmployeeInitialValues,
    onSubmit: async (values) => {
      // Create FormData object
      const formData = new FormData();
      formData.append("companyId", values.companyId);
      formData.append("salutation", values.salutation);
      formData.append("firstName", values.firstName);
      formData.append("middleName", values.middleName);
      formData.append("lastName", values.lastName);
      formData.append("DOB", values.DOB);
      formData.append("gender", values.gender);
      formData.append("contact", values.contact);
      formData.append("email", values.email);
      formData.append("designation", values.designation);
      formData.append("fathersName", values.fathersName);
      formData.append("fathersContact", values.fathersContact);
      formData.append("maritalStatus", values.maritalStatus);
      formData.append("panNumber", values.panNumber);
      formData.append("adharNumber", values.adharNumber);
      formData.append("passportNumber", values.passportNumber);
      formData.append("passportValidupto", values.passportValidupto);
      formData.append("departments", values.departments);
      formData.append("branch", values.branch);
      formData.append("joining_date", values.joining_date);
      formData.append("nameAsPerBank", values.nameAsPerBank);
      formData.append("bankName", values.bankName);
      formData.append("bankBranch", values.bankBranch);
      formData.append("accountNumber", values.accountNumber);
      formData.append("IFSCNumber", values.IFSCNumber);
      formData.append("upiId", values.upiId);
      formData.append("employeementType", values.employeementType);
      // formData.append("employeementStatus", values.employeementStatus);
      formData.append("educationLevel", values.educationLevel);
      formData.append("course", values.course);
      formData.append("specification", values.specification);
      formData.append("startYear", values.startYear);
      formData.append("passoutYear", values.passoutYear);
      formData.append("CGPA", values.CGPA);
      formData.append("deduction", values.deduction);
      formData.append("basicSalary", values.basicSalary);
      formData.append("empCode", values.empCode);
      formData.append("altContact", values.altContact);
      formData.append("bloodGroup", values.bloodGroup);
      formData.append("perosnalEmail", values.perosnalEmail);
      formData.append("spouseName", values.spouseName);
      formData.append("id", values.id);
      formData.append("motherName", values.motherName);
      formData.append("motherContact", values.motherContact);
      formData.append("spouseContact", values.spouseContact);
      formData.append("contactOffice", values.contactOffice);
      formData.append("emergencyContact", values.emergencyContact);
      //pLine1
      formData.append("pLine1", values.pLine1);
      formData.append("pLine2", values.pLine2);
      formData.append("pCity", values.pCity);
      formData.append("pState", values.pState);
      formData.append("pZipcode", values.pZipcode);
      formData.append("cLine1", values.cLine1);
      formData.append("cLine2", values.cLine2);
      formData.append("cCity", values.cCity);
      formData.append("cState", values.cState);
      formData.append("cZipcode", values.cZipcode);

      if (files instanceof File) {
        formData.append("logo", files); // Append only if it's a file object
      }

      // if (currentTab != 4) {
      //   handleNext();
      // } else {
      let res = await CreateEmployee(formData);

      // Handle the response
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        if (currentTab == 4) {
          navigate("/employee");
          formik.resetForm();
        } else {
          handleNext();
          console.log("resdwsdsdsdsdsd", res);

          navigate("/employee-edit", {
            state: { id: res.data.id, currentTab: currentTab + 1 },
          });
        }
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
        // }
      }
    },
    validationSchema:
      currentTab === 0
        ? employeeDatavalidationSchema
        : currentTab === 1
          ? persnalDatavalidationSchema
          : currentTab === 2
            ? IdentificationValidationSchema
            : currentTab === 3
              ? BankValidationSchema
              : currentTab === 4
                ? EducationValidationSchema
                : addEmployeeValidationSchema,
  });

  console.log("formik", formik);

  const handleNext = () => {
    setCurrentTab((prevTab) => prevTab + 1);
  };

  const gotoBack = () => {
    navigate("/employee");
  };

  const handlePrevious = () => {
    setCurrentTab((prevTab) => prevTab - 1);
    navigate("/employee-edit", {
      state: { id: editId, currentTab: currentTab - 1 },
    });
  };

  return (
    <div>
      {currentTab === 0 && (
        <EmployeeDataTab
          formik={formik}
          onNext={handleNext}
          isEdit={isEdit}
          gotoBack={gotoBack}
        />
      )}
      {currentTab === 1 && (
        <OfficialDetails
          formik={formik}
          onPrevious={handlePrevious}
          onNext={handleNext}
          isEdit={isEdit}
          gotoBack={gotoBack}
        />
      )}
      {currentTab === 2 && (
        <IdentiDetailTab
          formik={formik}
          onPrevious={handlePrevious}
          onNext={handleNext}
          gotoBack={gotoBack}
        />
      )}
      {currentTab === 3 && (
        <BankDetailsAdd
          formik={formik}
          onPrevious={handlePrevious}
          onNext={handleNext}
          gotoBack={gotoBack}
        />
      )}
      {currentTab === 4 && (
        <EduDetails
          formik={formik}
          onPrevious={handlePrevious}
          onNext={handleNext}
          gotoBack={gotoBack}
        />
      )}
    </div>
  );
};

export default FormContainer;
