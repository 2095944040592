import {
  Box,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Button, Heading, Input, Modal } from "../../components/Common";
import { Close, Done } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import { Search } from "../../components/Common/Table";
import { VscFilter } from "react-icons/vsc";
import { MdAccessibilityNew } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { BsCalendar2DateFill } from "react-icons/bs";
import {
  LeaveList,
  RejectLeave,
  ConfirmLeave,
  LeaveTypeDropdown,
} from "../../Service/Leave.service";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { filterHolidayInitialValues } from "../../helper/initialValues";
import moment from "moment";
import { Pagination } from "../../components/Common/Table";
import CommonSelect from "../../components/Common/Select";
import { EmployeeDropdown } from "../../Service/Employee.service";
import CommonInput from "../../components/Common/Input";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";

const LeaveandHolidayManage = () => {
  const navigat = useNavigate();
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [leaveType, setLeaveType] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [totalData, setTotalData] = useState(0);
  const [newRequest, setNewRequest] = useState(0);
  const [approvedRequest, setApprovedRequest] = useState(0);
  const [rejectRequest, setrejectRequest] = useState(0);
  const [nextHoliday, setNextHolidayRequest] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [leavedata, setLeaveData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [LeaveData, setLeaveTypeData] = useState([]);

  const dispatch = useDispatch();
  const formik1 = useFormik({
    initialValues: filterHolidayInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getLeaveList();
    },
  });

  const getLeaveList = async () => {
    setLeaveData([]);
    let selectQry = `?page=${
      page + 1
    }&limit=${rowsPerPage}&search=${search}&status=${1}&sortBy=${
      sortConfig.key
    }&sortFlag=${sortConfig.direction}&startDate=${
      formik1.values.startDate
    }&endDate=${formik1.values.endDate}&employeeId=${
      formik1.values.employee
    }&type=${formik1.values.leaveType}`;
    let res = await LeaveList(selectQry);
    setNewRequest(res?.data?.data?.newRequest);
    setApprovedRequest(res?.data?.data?.approveRequest);
    setrejectRequest(res?.data?.data?.rejectRequest);
    setNextHolidayRequest(res?.data?.data?.nextHoliday);
    if (res?.status) {
      setLeaveData(res?.data?.leaveData);
      setTotalData(res?.data?.total);
    } else {
      setLeaveData(null);
    }
  };

  useEffect(() => {
    getLeaveList();
  }, [search, page, rowsPerPage, sortConfig, refresh]);

  useEffect(() => {
    getEmployeeData();
  }, []);

  const getTypeDropdownData = async () => {
    let res = await LeaveTypeDropdown();
    if (res?.status) {
      setLeaveTypeData(res.data);
    } else {
      setLeaveTypeData([]);
    }
  };

  useEffect(() => {
    getTypeDropdownData();
  }, []);

  const getEmployeeData = async () => {
    let res = await EmployeeDropdown();
    if (res?.status) {
      setEmployeeData(res.data);
    } else {
      setEmployeeData([]);
    }
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
    formik1.resetForm();
    getLeaveList();
  };

  const handleChange = (event) => {
    setLeaveType(event.target.value);
  };

  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "approved"
        ? await ConfirmLeave(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "reject"
        ? await RejectLeave(isModalOpen?.id)
        : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getLeaveList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };
  const nextHolidayDate = moment(nextHoliday); // Convert nextHoliday to a Moment.js object
  const formattedDate = nextHolidayDate.format("YYYY"); // Extract the year

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Heading head={"Leave Management"} />
        <Box display={'flex'} gap={1}>
          <Button
            buttonName={"Add Leave"}
            size="small"
            onClick={() => {
              setIsModalOpen({
                open: true,
                currentComponent: "addleave",
              });
            }}
          />
          <Button
            buttonName={"Leave Management"}
            size="small"
            onClick={() => {
              setIsModalOpen({
                open: true,
                currentComponent: "addleaveManage",
              });
            }}
          />
        </Box>
      </Stack>
      <Stack gap={2}>
        <Grid container spacing={2} mt={1}>
          {/*  Cards */}

          <Grid item xs={12} sm={6} md={3}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{ width: "100%" }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography color={"#888"}>New Requests</Typography>
                  <Typography variant="h6" fontWeight="600">
                    {newRequest}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      background: "#ffc10720",
                      p: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <MdAccessibilityNew
                      style={{ fontSize: "30px", color: "#ffc107" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{ width: "100%", cursor: "pointer" }}
              onClick={() => navigat("/approve-leave")}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography color={"#888"}>Approve Requests</Typography>
                  <Typography variant="h6" fontWeight="600">
                    {approvedRequest}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      background: "#ffc10720",
                      p: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <TiTick style={{ fontSize: "30px", color: "#ffc107" }} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{ width: "100%", cursor: "pointer" }}
              onClick={() => navigat("/reject-leave")}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography color={"#888"}>Reject Requests</Typography>
                  <Typography variant="h6" fontWeight="600">
                    {rejectRequest}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      background: "#ffc10720",
                      p: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <RxCross2 style={{ fontSize: "30px", color: "#ffc107" }} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* sd */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{ width: "100%", cursor: "pointer" }}
              aria-describedby={id}
              variant="contained"
              onClick={() => navigat("/holiday")}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography color={"#888"}>Next Holiday</Typography>
                  <Typography variant="h6" fontWeight="600">
                    {nextHoliday
                      ? moment(nextHoliday).format("DD MMMM")
                      : "No Holiday"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      background: "#ffc10720",
                      p: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <BsCalendar2DateFill
                      style={{ fontSize: "30px", color: "#ffc107" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Stack>
          <Heading smallHead={"Total Leave Request"} />
          <Stack
            mb={1}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={2}
          >
            <Search search={search} setSearch={setSearch} setPage={setPage} />
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <Button
                buttonName={"Add Leave Balance"}
                size="small"
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "addleavebalance",
                  });
                }}
              />
              <Button
                buttonName={<VscFilter />}
                style={{ padding: "10px 11px" }}
                color="white"
                size="small"
                variant="contained"
                onClick={handleFilterClick}
                sx={{ mt: 2 }}
              />
            </Stack>
          </Stack>
          <Collapse Collapse in={showFilter}>
            <Stack
              mb={"12px"}
              sx={{ background: "#151515" }}
              flexDirection={"row "}
              gap={2}
              flexWrap={"wrap"}
              justifyContent={"flex-end"}
              p={1}
              alignItems={"center"}
            >
              <Grid className="filterinput">
                <Typography className="inputfont">{"Employee"}:</Typography>

                <CommonSelect
                  name="employee"
                  value={formik1.values.employee}
                  formik={formik1}
                  selectList={employeeData}
                  onChange={(e) =>
                    formik1.setFieldValue("employee", e.target.value)
                  }
                />
              </Grid>
              <Grid className="filterinput">
                <Typography className="inputfont">{"Leave Type"}:</Typography>
                <CommonSelect
                  label="Type"
                  formik={formik1}
                  name="leaveType"
                  onChange={(e) =>
                    formik1.setFieldValue("leaveType", e.target.value)
                  }
                  value={formik1.values.leaveType}
                  selectList={LeaveData}
                />
              </Grid>
              <Grid className="filterinput">
                <Typography className="inputfont">{"Start Date"}:</Typography>
                <CommonInput
                  type="date"
                  formik={formik1}
                  name="startDate"
                  isDate={true}
                />
              </Grid>
              <Grid className="filterinput">
                <Typography className="inputfont">{"End Date"}:</Typography>
                <CommonInput
                  type="date"
                  formik={formik1}
                  name="endDate"
                  isDate={true}
                />
              </Grid>

              <Box>
                <Button buttonName={"Filter"} onClick={formik1.handleSubmit} />
              </Box>
              <Box>
                <Button buttonName={"Clear"} onClick={() => clearFilter()} />
              </Box>
            </Stack>
          </Collapse>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Employee Name</TableCell>
                  <TableCell>Leave Type</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Requested Days</TableCell>
                  <TableCell>Leave Balance</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Confirmation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leavedata &&
                  leavedata?.map((row) => (
                    <TableRow key={row?.id}>
                      <TableCell>
                        {row?.firstName} {row?.lastName}
                      </TableCell>
                      <TableCell>
                        {row?.type === 1 && "1st Half"}
                        {row?.type === 2 && "2nd Half"}
                        {row?.type === 3 && "Casual Leave"}
                        {row?.type === 4 && "Sick leave"}
                        {row?.type === 5 && "Loss of pay / Leave with out pay"}
                      </TableCell>
                      <TableCell>
                        {moment(row?.startDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(row?.endDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{row?.leaveDays}</TableCell>
                      <TableCell>{row?.leaveBalance}</TableCell>
                      <TableCell>
                        {row?.status === 1 && "Pending"}
                        {row?.status === 2 && "Confirmed"}
                        {row?.status === 3 && "Rejected"}
                      </TableCell>
                      <TableCell>
                        <IconButton>
                          <Done
                            sx={{
                              bgcolor: "success.main",
                              color: "white.main",
                              borderRadius: "100%",
                            }}
                            onClick={() =>
                              setIsModalOpen({
                                open: true,
                                currentComponent: "approved",
                                head: "Approved Leave",
                                para: "Are you sure you want to Approved this leave?",
                                id: row?.id,
                              })
                            }
                          />
                        </IconButton>
                        <IconButton>
                          <Close
                            sx={{
                              bgcolor: "error.main",
                              color: "white.main",
                              borderRadius: "100%",
                            }}
                            onClick={() =>
                              setIsModalOpen({
                                open: true,
                                currentComponent: "reject",
                                head: "Reject Leave",
                                para: "Are you sure you want to Reject this Leave?",
                                id: row?.id,
                              })
                            }
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {leavedata == null ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center !important",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="8"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                ) : leavedata && leavedata.length == 0 ? (
                  <TableRowsLoader colNumber={8} rowsNum={10} />
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {leavedata != null && (
          <Pagination
            count={10}
            shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>


      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({
            open: false,
            currentComponent: "",
            id: "",
            isEdit: "",
          });
        }}
        handelClick={changeStatus}
        refresh={refresh}
        setRefresh={setRefresh}
      />


    </>
  );
};

export default LeaveandHolidayManage;
