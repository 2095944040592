import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Grid, Typography, Stack } from "@mui/material";
import { BackButton, Heading, Input } from "../../components/Common";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CompanyDetail,
  CreateCompany,
  UpdateCompany,
} from "../../Service/Company.service";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { addCompanyInitialValues } from "../../helper/initialValues";
import { addCompanyValidationSchema } from "../../helper/validation";
import CommonSelect from "../../components/Common/Select";

const AddCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [files, setFiles] = useState(null);
  const { state } = useLocation();

  useEffect(() => {
    if (state && state?.isEdit && state?.id) {
      setIsEdit(true);
      getCompanyDetails(state.id);
      setEditId(state.id);
    } else {
      setEditId(null);
      setIsEdit(false);
    }
  }, [state]);

  const getCompanyDetails = async (id) => {
    let res = await CompanyDetail(id);
    if (res?.status) {
      formik.setValues({
        companyName: res.data.name || "",
        email: res.data.email || "",
        contactNumber: res.data.mobile || "",
        website: res.data.website || "",
        address: res.data.address || "",
        zipcode: res.data.zipcode || "",
        state: res.data.state || "",
        city: res.data.city || "",
        probationPeriod: res.data.probationPeriod || "",
      });
      setFiles(res.data.logo || null);
    }
  };

  const formik = useFormik({
    initialValues: addCompanyInitialValues,
    validationSchema: addCompanyValidationSchema,
    onSubmit: async (values) => {
      // Create FormData object
      const formData = new FormData();
      formData.append("name", values.companyName);
      formData.append("email", values.email);
      formData.append("mobile", values.contactNumber);
      formData.append("website", values.website);
      formData.append("address", values.address);
      formData.append("zipcode", values.zipcode);
      formData.append("state", values.state);
      formData.append("city", values.city);
      formData.append("probationPeriod", values.probationPeriod);
      if (files instanceof File) {
        formData.append("logo", files); // Append only if it's a file object
      }

      // Determine if it's an update or create operation
      let res;
      if (editId) {
        res = await UpdateCompany(editId, formData);
      } else {
        res = await CreateCompany(formData);
      }

      // Handle the response
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        navigate("/company");
        formik.resetForm();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
  });
  const handleRemoveImage = () => {
    setFiles(null);
  };

  return (
    <>
      <Stack mb={2}>
        <Stack gap={2}>
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <BackButton />
            <Heading head="Add Company" />
          </Stack>
        </Stack>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack className="Sideempdetails">
          {/* Form Fields */}
          <Grid container spacing={2}>
            <Grid item xs={3} sm={3}>
              <Typography
                fontSize={{ xs: "13px", sm: "14px" }}
                fontWeight={500}
                mb={"2px"}
              >
                {"Upload Company Logo"}
              </Typography>

              {/* Show the uploaded image preview if available */}
              {files && (
                <div
                  style={{
                    marginBottom: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      files instanceof File ? URL.createObjectURL(files) : files
                    }
                    alt="Company Logo"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                      marginRight: "10px",
                    }}
                  />
                  <Button
                    variant="text"
                    color="error" // This will make the button red
                    onClick={handleRemoveImage}
                  >
                    Remove
                  </Button>
                </div>
              )}

              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setFiles(file);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Input
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                labelinput="Company Name"
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <CommonSelect
                name="probationPeriod"
                value={formik.values.probationPeriod}
                onChange={(e) =>
                  formik.setFieldValue("probationPeriod", e.target.value)
                }
                variant="outlined"
                fullWidth
                formik={formik}
                labelinput="Probation Period"
                selectList={[
                  {
                    name: "1 Month",
                    id: 1,
                  },
                  {
                    name: "2 Month",
                    id: 2,
                  },
                  {
                    name: "3 Month",
                    id: 3,
                  },
                  {
                    name: "4 Month",
                    id: 4,
                  },
                  {
                    name: "5 Month",
                    id: 5,
                  },
                  {
                    name: "6 Month",
                    id: 6,
                  },
                  {
                    name: "7 Month",
                    id: 7,
                  },
                  {
                    name: "8 Month",
                    id: 8,
                  },
                  {
                    name: "9 Month",
                    id: 9,
                  },
                  {
                    name: "10 Month",
                    id: 10,
                  },
                  {
                    name: "11 Month",
                    id: 11,
                  },
                  {
                    name: "12 Month",
                    id: 12,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Input
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                labelinput="Email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Input
                name="contactNumber"
                type="text"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                labelinput="Contact Number"
                error={
                  formik.touched.contactNumber &&
                  Boolean(formik.errors.contactNumber)
                }
                helperText={
                  formik.touched.contactNumber && formik.errors.contactNumber
                }
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Input
                name="website"
                type="text"
                value={formik.values.website}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                labelinput="Website"
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Input
                name="address"
                type="text"
                value={formik.values.address}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                labelinput="Address"
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Input
                name="zipcode"
                type="text"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                labelinput="Zip Code"
                error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                helperText={formik.touched.zipcode && formik.errors.zipcode}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Input
                name="state"
                type="text"
                value={formik.values.state}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                labelinput="State"
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Input
                name="city"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                labelinput="City"
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid
              item
              xs={12}
              mt={2}
              display={"flex"}
              justifyContent={"flex-end"}
              gap={1}
            >
              {" "}
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/company")}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </>
  );
};

export default AddCompany;
