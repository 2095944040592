import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import { BackButton, Button, Heading, Input } from "../../../components/Common";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import logo from "../../../../src/assets/images/main_logo.png";
import { useLocation } from "react-router-dom";
import { payslipDetail } from "../../../Service/paySlip.service";
import moment from "moment";

const ViewPayslip = () => {
  const componentRef = useRef();
  const { state } = useLocation();
  const [payslipData, setPayslipData] = React.useState({});

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => generatePdf(),
  });

  useEffect(() => {
    if (state && state.id) {
      console.log("state", state);

      getPaySlipDetails(state.id);
    }
  }, [state]);

  const getPaySlipDetails = async (id) => {
    const response = await payslipDetail(id);
    if (response && response.status) {
      setPayslipData(response.data);
    }
  };

  console.log("payslipData", payslipData);

  const generatePdf = () => {
    const element = componentRef.current;
    const date = new Date();
    const dateString = date.toLocaleDateString().replace(/\//g, "-");
    const timeString = date.toLocaleTimeString().replace(/:/g, "-");
    const filename = `payslip_${dateString}_${timeString}.pdf`;

    const opt = {
      margin: 0.5,
      filename: filename,
      image: { type: "jpeg", quality: 1.0 }, // Higher quality
      html2canvas: { scale: 3 }, // Higher scale for better quality
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
  };

  const formatMonthYear = (dateString) => {
    if (!dateString) {
      return ""; // Return an empty string or handle the error as needed
    }

    // Split the input string to get year and month
    const [year, month] = dateString.split("-").map(Number);

    // Create a Date object using the year and month
    const date = new Date(year, month - 1); // month is 0-based in JS

    // Format the month to short month name (e.g., "Sep")
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options).replace(" ", "-");
  };

  const calculateDaysInMonth = (monthString) => {
    if (!monthString) {
      // Handle the case where monthString is undefined or null
      return 0; // Or some other default value or error handling
    }

    const [year, month] = monthString.split("-").map(Number);
    // new Date(year, month, 0) gives the last day of the previous month
    const days = new Date(year, month, 0).getDate();
    return days;
  };

  return (
    <>
      <Stack gap={2} mb={1}>
        <Stack
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <BackButton />
          {/* <Input type="month" /> */}
        </Stack>
        <Stack sx={{ display: "flex", gap: "10px", flexDirection: "row" }}>
          <Button buttonName="Download" color="white" onClick={handlePrint} />
          {/* <Button buttonName="Email" color="red" /> */}
        </Stack>
        <Box padding={7}>
          <Stack ref={componentRef}>
            <Stack
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
                padding: "10px 30px",
              }}
              gap={3}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    height: "fit-content",
                  }}
                >
                  <img
                    src={logo}
                    alt="company logo"
                    style={{ width: "150px", objectFit: "contain" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                      width: "335px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      Asia Pacific Ceramic
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "gray.main",
                      }}
                    >
                      306, Eden Ceramic City, Opp. Hotel Ravi Residency, NH 8A,
                      Lalpar, Morbi-363642, Gujarat, India.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0px",
                    flexDirection: "column",
                    height: "fit-content",
                  }}
                >
                  <Typography
                    fontSize={{ xs: "13px" }}
                    sx={{ fontWeight: "600", color: "gray.main" }}
                  >
                    Payslip For the Month
                  </Typography>
                  <Typography fontSize={"20px"} textAlign={"right"}>
                    {formatMonthYear(payslipData?.salaryMonth)}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ height: "1.5px", backgroundColor: "gray.main" }} />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Typography
                    fontSize={"20px"}
                    fontWeight={"600"}
                    color={"black"}
                  >
                    Employee Details
                  </Typography>
                  <Stack gap={1} mt={2}>
                    <Stack flexDirection={"row"} gap={2}>
                      <Typography className="paysliptexth1" width={"150px"}>
                        Employee Name
                      </Typography>
                      :
                      <Typography className="paysliptexth2">
                        {payslipData?.employeeId?.salutation}{" "}
                        {payslipData?.employeeId?.firstName}{" "}
                        {payslipData?.employeeId?.middleName}{" "}
                        {payslipData?.employeeId?.lastName}
                      </Typography>
                    </Stack>
                    <Stack flexDirection={"row"} gap={2}>
                      <Typography className="paysliptexth1" width={"150px"}>
                        Employee ID
                      </Typography>
                      :
                      <Typography className="paysliptexth2">
                        {payslipData?.employeeId?.id}
                      </Typography>
                    </Stack>
                    <Stack flexDirection={"row"} gap={2}>
                      <Typography className="paysliptexth1" width={"150px"}>
                        Pay Period
                      </Typography>
                      :
                      <Typography className="paysliptexth2">
                        {formatMonthYear(payslipData?.salaryMonth)}
                      </Typography>
                    </Stack>
                    <Stack flexDirection={"row"} gap={2}>
                      <Typography className="paysliptexth1" width={"150px"}>
                        Pay Date
                      </Typography>
                      :
                      <Typography className="paysliptexth2">
                        {moment(payslipData?.createdAt).format("DD-MM-YYYY")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Box>
                  <Card sx={{ width: 250 }}>
                    <Box sx={{ 
                      // bgcolor: "#ffc10794", 
                      p: 2 }}>
                      <Typography
                        style={{
                          fontSize: "25px",
                          color: "#000",
                          fontWeight: "600",
                        }}
                      >
                        ₹{Math.round(payslipData?.net_salary)}
                      </Typography>
                      <Typography sx={{ fontSize: "13px" }}>
                        Employee Net Pay
                      </Typography>
                    </Box>
                    <CardContent>
                      <Stack flexDirection={"row"} gap={2}>
                        <Typography className="paysliptexth2" width={"100px"}>
                          Paid Days
                        </Typography>
                        <Typography className="paysliptexth1">
                          :
                          {" " + calculateDaysInMonth(payslipData?.salaryMonth)}
                        </Typography>
                      </Stack>
                      <Stack flexDirection={"row"} gap={2}>
                        <Typography className="paysliptexth2" width={"100px"}>
                          LOP Days
                        </Typography>
                        <Typography className="paysliptexth1">
                          : {" " + payslipData?.Lop}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                gap={4}
                p={1}
                borderRadius="4px"
                border="1px solid rgba(0, 0, 0, 0.12)"
              >
                {/* Earnings Section */}
                <Stack width="100%" spacing={3}>
                  <Box
                    borderBottom="1px dashed #000"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography className="paysliptexth1"> EARNINGS</Typography>
                    <Typography className="paysliptexth1">AMOUNT</Typography>
                  </Box>
                  <Box>
                    <Box display="flex" justifyContent="space-between">
                      <span className="paysliptexth2">Basic Pay</span>
                      <span className="paysliptexth2">
                        ₹{Math.round(payslipData?.monthlySalary)}
                      </span>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <span className="paysliptexth2">
                        House Rent Allowance
                      </span>
                      <span className="paysliptexth2">₹0.00</span>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <span className="paysliptexth2">&nbsp;{"  "}</span>
                      <span className="paysliptexth2"> {""}</span>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={1}
                      // bgcolor="#4A4A4A"
                      // color="white.main"
                      p={1}
                      borderRadius="4px"
                    >
                      <Typography sx={{fontWeight: 600 }}>Gross Earnings:INR</Typography>
                      <Typography sx={{fontWeight: 600 }}>
                        {" "}
                        ₹{Math.round(payslipData?.monthlySalary)}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>

                {/* Deductions Section */}
                <Stack width="100%" spacing={3}>
                  <Box
                    borderBottom="1px dashed #000"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography className="paysliptexth1">
                      DEDUCTIONS
                    </Typography>
                    <Typography className="paysliptexth1">AMOUNT</Typography>
                  </Box>
                  <Box>
                    <Box display="flex" justifyContent="space-between">
                      <span className="paysliptexth2">INCOME TAX</span>
                      <span className="paysliptexth2">
                        ₹{Math.round(payslipData?.tax_details)}
                      </span>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <span className="paysliptexth2">LOSS OF PAY</span>
                      <span className="paysliptexth2">
                        ₹{Math.round(parseFloat(payslipData?.leaveDeducted))}
                      </span>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <span className="paysliptexth2">Sequrity Deposit</span>
                      <span className="paysliptexth2">
                        ₹{Math.round(parseFloat(payslipData?.deductions))}
                      </span>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={1}
                      // bgcolor="#4A4A4A"
                      // color="white.main"
                      p={1}
                      fontWeight={700}
                      borderRadius="4px"
                    
                    >
                      <Typography   sx={{fontWeight: 600 }}>Total Deductions:INR</Typography>
                      <Typography   sx={{fontWeight: 600 }}>
                        ₹
                        {Math.round(
                          parseFloat(payslipData?.monthlySalary) -
                            parseFloat(payslipData?.net_salary)
                        )}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                borderRadius="4px"
                border="1px solid rgba(0, 0, 0, 0.12)"
              >
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    TOTAL NET PAYABLE
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Gross Earnings - Total Deductions
                  </Typography>
                </Box>
                <Box 
                // bgcolor="rgba(0, 255, 0, 0.2)" 
                p={2} borderRadius="4px">
                  <Typography variant="h6" fontWeight="bold">
                    ₹{Math.round(parseFloat(payslipData?.net_salary))}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  marginTop: "4px",
                }}
              >
                {/* <Stack className="horizontal-stripper-line"> </Stack>
                <Typography fontSize={"13px"}>This is computer generated document does not require any signature</Typography>
                <Stack className="horizontal-stripper-line"> </Stack> */}
                <div
                  style={{ width: "auto", color: "red", marginRight: "4px" }}
                >
                  *
                </div>
                <Typography fontSize={"13px"}>
                  This is computer generated document does not require any
                  signature
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default ViewPayslip;
