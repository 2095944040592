import React, { useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload } from '../../../components/Common'
import ExcelUpload from '../../../components/Common/ExcelUpload'
import CommonSelect from '../../../components/Common/Select'
import { useSelector } from 'react-redux'
import { use } from 'i18next'

const UploadProduct = ({ handleClose, formik, modalOpen, handelClick, companyData, files, setFiles, companyId, setCompanyId }) => {

  const { user } = useSelector((state) => state.root.auth);
  console.log('sasasadfdgfgnhjkhgfd', user)

  useEffect(() => {
    if (user?.role != 1) {
      formik.setFieldValue('companyId', user?.companyId)
    }
  }, [user])

  const downloadSampleCSV = () => {
    const csvContent = [
      ['emp_code', 'departments', 'designation', 'salutation', 'emp_fname', 'emp_mname', 'emp_lname', 'dob', 'gender', 'marrital_status', 'date_of_joining', 'aadhar_no', 'pan_no', 'spouse_name', 'bank_name', 'ac_no', 'ifsc_code', 'ac_type', 'bank_ac_name', 'branch', 'upiId', 'cLine1', 'cLine2', 'cCity', 'cState', 'cZipcode', 'pLine1', 'pLine2', 'pCity', 'pState', 'pZipcode', 'mobile', 'eme_person_name', 'eme_contact', 'email_personal', 'email_official', 'passport_no', 'passportValidupto'],
      // ['123', 'John Doe', '1990-01-01', 'Married', 'Mr.', 'John', 'Doe', 'Doe', '123456789012', 'ABCDE1234F', 'Jane Doe', 'HDFC', '1234567890123456', 'HDFC0000123', 'Savings', 'John Doe', 'Mumbai', 'Mumbai', 'Ahmedabad', '1234567890', 'Jane Doe', '1234567890', 'test@gmail.com', 'company@gmail.com', '1234567890']
    ]

    let csvString = csvContent.map(row => row.join(',')).join('\n');
    let blob = new Blob([csvString], { type: 'text/csv' });
    let url = URL.createObjectURL(blob);

    let a = document.createElement('a');
    a.href = url;
    a.download = 'sample_employee_upload.csv';
    a.click();
    URL.revokeObjectURL(url);
  }

  console.log('companyData', companyData)

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Upload Product
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {/* <DialogActions sx={{display:"flex",justifyContent:"flex-start"}}>
        <Button buttonName='Download Sample CSV' size='small' onClick={downloadSampleCSV}/>
      </DialogActions> */}
      <DialogContent sx={{ width: '500px' }}>
        <Grid container spacing={2} maxWidth={{ sm: '1200px' }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <Typography className="inputfont">Company:</Typography>
            <CommonSelect
              name="companyId"
              label="Company"
              value={formik.values.companyId}
              formik={formik}
              disabled={user?.role != 1}
              selectList={companyData}
              onChange={(e) =>
                formik.setFieldValue("companyId", e.target.value)
              }
              selectHead="Select Company"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ExcelUpload files={files} setFiles={setFiles} singleImage={true}
              accept=".xls,.xlsx,.csv" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Button buttonName='Download Sample CSV' size='small' onClick={downloadSampleCSV} />
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Button buttonName='Cancel' size='small' color='white' onClick={handleClose} />
          <Button buttonName='Upload' size='small' onClick={handelClick} />
        </Box>
      </DialogActions>
    </>
  )
}

export default UploadProduct
