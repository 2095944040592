import React, { useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button } from "../../components/Common";
import { useFormik } from "formik";
import {
  leaveAddInitialValues,
  salaryDetailAddInitialValues,
} from "../../helper/initialValues";
import {
  addSalaryDetailValidationSchema,
  leaveAddValidationSchema,
} from "../../helper/validation";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { AddLeave } from "../../Service/Leave.service";
import CommonInput from "../../components/Common/Input";
import { SalaryDeduction } from "../../Service/Salary.service";

const AddSalaryDetailsModal = ({
  handleClose,
  modalOpen,
  refresh,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: salaryDetailAddInitialValues,
    onSubmit: async (value) => {
      const payload = {
        employeeId: modalOpen.employeeData?.id,
        basicSalary: value.basicSalary,
        deduction: value.sequrityDeposit == "" ? 0 : value.sequrityDeposit,
      };

      let res = await SalaryDeduction(payload);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        formik.resetForm();
        setRefresh(!refresh);
        handleClose();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: addSalaryDetailValidationSchema,
  });

  useEffect(() => {
    if (modalOpen.employeeData)
      formik.setValues({
        employeeId: modalOpen.employeeData?.id,
        basicSalary: modalOpen.employeeData?.basicSalary,
        sequrityDeposit: modalOpen.employeeData?.deduction,
      });
  }, [modalOpen]);

  console.log("formikdsdsdsdsds", formik.errors);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Edit Salary
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <CommonInput
              labelinput="Gross Salary"
              placeholder="Gross Salary"
              formik={formik}
              name="basicSalary"
              maxLength={200}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <CommonInput
              labelinput="Sequrity Deposit ( % )"
              placeholder="Sequrity Deposit"
              formik={formik}
              name="sequrityDeposit"
              maxLength={255}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Submit"
          size="small"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default AddSalaryDetailsModal;
