import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import nettyfylogo from "../../assets/images/nettyfylogo.webp";
import { CompanyDetail } from "../../Service/Company.service";
import { BackButton, Button, Heading } from "../../components/Common";
import { useLocation, useNavigate } from "react-router-dom";

const Company_Details = () => {
  const [companyData, setCompanyData] = React.useState(null);

  const { state } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (state && state?.isEdit && state?.id) {
      getCompanyDetails(state.id);
    }
  }, []);

  const getCompanyDetails = async (id) => {
    let res = await CompanyDetail(id);
    if (res?.status) {
      setCompanyData(res.data);
    }
  };

  return (
    <>
      <Stack mb={2}>
        <Stack gap={2}>
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <Box  sx={{
                display: "flex",
                gap: "10px",
                //place content at the end
                alignItems: "center",
              }}>
              <BackButton />
              <Heading head="Company Details" />
            </Box>
            <Stack
              sx={{
                display: "flex",
                gap: "10px",
                //place content at the end
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                buttonName="Edit"
                size="small"
                onClick={() => {
                  navigate("/add-company", {
                    state: { id: state?.id, isEdit: true },
                  });
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ minWidth: "300px" }}>
        <Card sx={{ maxWidth: "450px", padding: "20px" }}>
          <CardContent>
            <Box>
              <Box mb={3}>
                <img
                  src={companyData?.logo}
                  alt="Company Logo"
                  style={{ width: "100%", maxWidth: "100px" }}
                />
              </Box>
              <Box
                sx={{ display: "flex", gap: "10px", flexDirection: "column" }}
              >
                <span
                  variant="h5"
                  className="mainH1"
                  style={{ fontWeight: "bold" }}
                >
                  {companyData?.name}
                </span>
                <span variant="body1" className="mainH2">
                  Email: {companyData?.email}
                </span>
                <span variant="body1" className="mainH2">
                  Contact: {companyData?.mobile}
                </span>
                <span variant="body1" className="mainH2">
                  Website:{" "}
                  <a
                    href={companyData?.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {companyData?.website}
                  </a>
                </span>
                <span variant="body1" className="mainH2">
                  Probation Period: {companyData?.probationPeriod + 1} Months
                </span>

                <Typography variant="body1" className="mainH2">
                  Status:{" "}
                  <span style={{ fontWeight: "bold", color: "green" }}>
                    {companyData?.status == 1 ? "Active" : "Inactive"}
                  </span>
                </Typography>
                <span
                  variant="body1"
                  className="mainH2"
                  style={{ display: "flex", flexDirection: "row", gap: "2px" }}
                >
                  Address:{" "}
                  <span>
                    {companyData?.address ? companyData?.address + "," : ""}
                  </span>{" "}
                  <span>
                    {companyData?.city ? companyData?.city + "," : ""}
                  </span>{" "}
                  <span>
                    {companyData?.state ? companyData?.state + "," : ""}
                  </span>{" "}
                  <span>
                    {companyData?.country ? companyData?.country + "," : ""}
                  </span>
                  <span>
                    {companyData?.zipcode ? companyData?.zipcode : ""}
                  </span>
                </span>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};
export default Company_Details;
