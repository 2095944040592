import { Box, Chip, Divider, Stack, Typography, chip } from "@mui/material";
import React from "react";
import profile_image from "../../../assets/images/profile_image.jpg";
import { MdEdit } from "react-icons/md";
import { Button, Heading } from "../../../components/Common";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const EmployeeInfo = ({ employeeData }) => {
  const navigate = useNavigate();
  return (
    <>
      <Stack gap={2} display={"flex"} flexDirection={"row"}>
        <Stack gap={2} width={"100%"}>
          <Stack className="Sideempdetails" gap={1}>
            <Stack>
              <Box className="headingPart">
                <span className="mainH1">Employee Data</span>
                {/* <Box className="editicon">
                  <MdEdit onClick={() => navigate("/FormContainer")} />
                </Box> */}
                <Button
                  buttonName={`Edit`}
                  onClick={() =>
                    navigate("/employee-add", {
                      state: { id: employeeData?.id },
                    })
                  }
                />
              </Box>
              <Stack gap={2}>
                <Divider />
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Salutation</span>
                    <span className="mainH2">{employeeData?.salutation}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">First Name</span>
                    <span className="mainH2">{employeeData?.firstName}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Middle Name</span>
                    <span className="mainH2">{employeeData?.middleName}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Last Name</span>
                    <span className="mainH2">{employeeData?.lastName}</span>
                  </Box>
                </Box>
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Gender</span>
                    <span className="mainH2">
                      {employeeData?.gender == 1 ? "female" : "male"}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Date of Birth</span>
                    <span className="mainH2">
                      {" "}
                      {moment(employeeData?.DOB).format("DD/MM/YYYY")}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Contact Number</span>
                    <span className="mainH2">+91 {employeeData?.contact}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      wordBreak: "break-word", // Ensures long words break and wrap to the next line
                      overflowWrap: "break-word",
                    }}
                  >
                    <span className="mainH3">Email</span>
                    <span className="mainH2">{employeeData?.email}</span>
                  </Box>
                </Box>
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Designation</span>
                    <span className="mainH2">{employeeData?.designation}</span>
                  </Box>
                </Box>
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Department</span>
                    <span className="mainH2">{employeeData?.departments}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Company Name</span>
                    <span className="mainH2">
                      {employeeData?.companyData?.name}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Branch</span>
                    <span className="mainH2">{employeeData?.branch}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Joining Date</span>
                    <span className="mainH2">
                      {moment(employeeData?.joining_date).format("DD/MM/YYYY")}
                    </span>
                  </Box>
                </Box>
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Employement Type</span>
                    <span className="mainH2">
                      {employeeData?.employeementType == 1
                        ? <Chip label="Permanent" color="success" />
                        : employeeData?.employeementType == 2
                          ? <Chip label="Contract" color="warning" />
                          : employeeData?.employeedmentType == 3
                            ? <Chip label="Full Time" color="info" />
                            : employeeData?.employeementType == 4
                              ? <Chip label="Contract" color="primary" />
                              : <Chip label="Unknown" color="error" />}
                    </span>
                  </Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Employement Status</span>
                    <span className="mainH2">
                      {employeeData?.employeementStatus === 1
                        ? "Active"
                        : employeeData?.employeementStatus === 0
                          ? "Inactive"
                          : "Unknown"}
                    </span>
                  </Box> */}
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="Sideempdetails" gap={1}>
            <Stack>
              <Box className="headingPart">
                <span className="mainH1">Personal Data</span>
                {/* <Box className="editicon">
                  <MdEdit />
                </Box> */}
              </Box>
              <Stack gap={2}>
                <Divider />
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Marital Status</span>
                    <span className="mainH2">
                      {employeeData?.maritalStatus ? "Married" : "Unmarried"}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Father's Name</span>
                    <span className="mainH2">{employeeData?.fathersName}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Father's Contact Number</span>
                    <span className="mainH2">
                      +91 {employeeData?.fathersContact}
                    </span>
                  </Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Mother's Name</span>
                    <span className="mainH2">Sherya</span>
                  </Box> */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Contact Number</span>
                    <span className="mainH2">+91 8790684862</span>
                  </Box> */}
                </Box>
                <Box className="infoboxgrid">
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Mother's Date of Birth</span>
                    <span className="mainH2">21/01/1980</span>
                  </Box> */}
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="Sideempdetails" gap={1}>
            <Stack>
              <Box className="headingPart">
                <span className="mainH1">Identification Details</span>
                {/* <Box className="editicon">
                  <MdEdit />
                </Box> */}
              </Box>
              <Stack gap={2}>
                <Divider />
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Pan Number</span>
                    <span className="mainH2">{employeeData?.panNumber}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Aadhar Number</span>
                    <span className="mainH2">{employeeData?.adharNumber}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Passport Number</span>
                    <span className="mainH2">
                      {employeeData?.passportNumber}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Passport Valid Upto</span>
                    <span className="mainH2">
                      {moment(employeeData?.passportValidupto).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="Sideempdetails" gap={1}>
            <Stack>
              <Box className="headingPart">
                <span className="mainH1">Bank Details</span>
                {/* <Box className="editicon">
                  <MdEdit />
                </Box> */}
              </Box>
              <Stack gap={2}>
                <Divider />
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Bank Name</span>
                    <span className="mainH2">{employeeData?.bankName}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Bank Branch Name</span>
                    <span className="mainH2">{employeeData?.bankBranch}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Name As Per Bank Record</span>
                    <span className="mainH2">
                      {employeeData?.nameAsPerBank}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Account Number</span>
                    <span className="mainH2">
                      {employeeData?.accountNumber}
                    </span>
                  </Box>
                </Box>
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">IFSC Number</span>
                    <span className="mainH2">{employeeData?.IFSCNumber}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">UPI id</span>
                    <span className="mainH2">{employeeData?.upiId}</span>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="Sideempdetails" gap={1}>
            <Stack>
              <Box className="headingPart">
                <span className="mainH1">Education Details</span>
                {/* <Box className="editicon">
                  <MdEdit />
                </Box> */}
              </Box>
              <Stack gap={2}>
                <Divider />
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Education Level</span>
                    <span className="mainH2">
                      {employeeData?.educationLevel}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Course</span>
                    <span className="mainH2">
                      {employeeData?.course}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Specialization</span>
                    <span className="mainH2">
                      {employeeData?.specification}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Start Year</span>
                    <span className="mainH2">
                      {moment(employeeData?.startYear).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </Box>
                </Box>
                <Box className="infoboxgrid">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">Passout Year</span>
                    <span className="mainH2">
                      {moment(employeeData?.passoutYear).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span className="mainH3">CGPA</span>
                    <span className="mainH2">
                      {employeeData?.CGPA}
                    </span>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default EmployeeInfo;
