import { StoreUserBrowserId } from "./Service/notification.service";

export const useUserNotificationSubscribeHook = () => {

  async function regSw() {
    try {
      if ('serviceWorker' in navigator) {
        const url = `http://localhost:3000/service-worker.js`;
        const reg = await navigator.serviceWorker.register(url, { scope: '/' });
        console.log('ServiceWorker registration successful with scope of: ', reg);
        return reg;
      }
    } catch (error) {
      console.log('ServiceWorker registration failed: ', error);
    }

  }




  async function subscribe(serviceWorkerReg, id) {
    try {
      let subscription = await serviceWorkerReg.pushManager.getSubscription();
      if (serviceWorkerReg.permission === 'default') {
        serviceWorkerReg.requestPermission().then(permission => {
          if (permission === 'granted') {
            // User has granted permission, proceed with subscribing to push notifications
          } else {
            console.error('Permission denied for push notifications.');
          }
        });
      }

      if (subscription == null) {

        subscription = await serviceWorkerReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: "BK-EHjzGUmhZZVGt23VuKZsgLw98z00fYQfXXTCmQE_MzbIsRzy2XDDusyOYJujYs3ua9_13H28w_Flx9UScsvE",
        });

        const payload = {
          subscription: JSON.stringify(subscription),
          userId: id,
        }





        const { data } = await StoreUserBrowserId(payload);


      }
    } catch (error) {
      console.log('Error in subscription: ', error);
    }
  }

  async function registerAndSubscribe(id) {
    try {
      const serviceWorkerReg = await regSw();

      await subscribe(serviceWorkerReg, id);
    } catch (error) {
      return error;
    }
  }

  return { regSw, subscribe, registerAndSubscribe };
};
