import { deleteRequest, get, post, put } from "../web.request";

export const HolidayList = async (query) => {
  return await get(`/holidayList${query}`)
}

export const CreateHoliday = async (data) => {
  return await post(`/createHoliday `, data);
}

export const UpdateHoliday = async (id, data) => {
  return await put(`/updateHoliday/${id}`, data);
}

export const HolidayDetail = async (id) => {
  return await get(`/holidayDetail/${id}`);
}

export const DeleteHoliday = async (id) => {
  return await deleteRequest(`/deleteHoliday/${id}`);
}

export const StatusChangeHoliday = async (id, data) => {
  return await put(`/statusChangeHoliday/${id}`, data);
}
