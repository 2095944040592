import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Heading,
  Input,
  MenuButton,
  Modal,
} from "../../../components/Common";
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Search from "../../../components/Common/Table/Search";
import { HiOutlineTrash } from "react-icons/hi";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdBlock, MdGroups, MdMoreVert, MdOutlinePaid } from "react-icons/md";
import { BsDownload, BsEye } from "react-icons/bs";
import { VscFilter } from "react-icons/vsc";
import { EmailOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { TbCurrencyDollarOff } from "react-icons/tb";
import {
  payslipList,
  payslipStatusChange,
} from "../../../Service/paySlip.service";
import TableRowsLoader from "../../../components/Common/Loader/Skeleton";
import {
  payslipInitialValues,
  salaryInitialValues,
} from "../../../helper/initialValues";
import CommonSelect from "../../../components/Common/Select";
import CommonInput from "../../../components/Common/Input";
import { EmployeeDropdown } from "../../../Service/Employee.service";
import { CompanyDropdown } from "../../../Service/Company.service";
import { useFormik } from "formik";

const PayslipGeneration = () => {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const [selected, setSelected] = useState("unpaid");
  const [status, setstatus] = useState("");
  const [roledata, setRoleData] = useState([]);
  const [batch, setBatch] = useState("");
  const [payslipdata, setPaySlipData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [statusChangeData, setStatusChangeData] = useState({
    id: "",
    status: "",
  });

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const getPaySlipData = async () => {
    let selectQry = `?page=${page}&limit=10&search=${search}&companyId=${formik1.values.companyId}&employeeId=${formik1.values.employee}&date=${formik1.values.date}`;
    let res = await payslipList(selectQry);
    if (res?.status) {
      setPaySlipData(res.data);
    } else {
      setPaySlipData([]);
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  const getEmployeeData = async () => {
    let res = await EmployeeDropdown();
    if (res?.status) {
      setEmployeeData(res.data);
    } else {
      setEmployeeData([]);
    }
  };
  const getDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);
  React.useEffect(() => {
    getPaySlipData();
  }, [search]);

  const statusChange = async (id, status) => {
    const payload = {
      status: statusChangeData.status,
      id: statusChangeData.id,
    };
    let res = await payslipStatusChange(payload);
    if (res?.status) {
      getPaySlipData();
      setIsModalOpen({ open: false, currentComponent: "" });
    }
  };

  console.log("payslipdata", payslipdata);

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
    formik1.resetForm();
    formik1.handleSubmit();
  };

  const handleBatch = (event) => {
    setBatch(event.target.value);
  };

  const handleSelect = (event) => {
    setstatus(event.target.value);
  };

  const formik1 = useFormik({
    initialValues: payslipInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getPaySlipData();
    },
  });

  return (
    <>
      <Heading head={"Payslip Generate"} />
      <Stack gap={1}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{ width: "100%" }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography color={"#888"}>Total Employee</Typography>
                  <Typography variant="h6" fontWeight="600">
                    {payslipdata?.summary?.totalUniqueEmployees}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      background: "#ffc10720",
                      p: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <MdGroups style={{ fontSize: "30px", color: "#ffc107" }} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{ width: "100%" }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography color={"#888"}>Total Paid</Typography>
                  <Typography variant="h6" fontWeight="600">
                    {payslipdata?.summary?.totalPaid}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      background: "#ffc10720",
                      p: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <MdOutlinePaid
                      style={{ fontSize: "30px", color: "#ffc107" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{ width: "100%" }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography color={"#888"}>Total Unpaid</Typography>
                  <Typography variant="h6" fontWeight="600">
                    {payslipdata?.summary?.totalUnPaid}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      background: "#ffc10720",
                      p: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <TbCurrencyDollarOff
                      style={{ fontSize: "30px", color: "#ffc107" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              className="card_container border_card"
              p={"12px 16px"}
              sx={{ width: "100%" }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography color={"#888"}>Total Leave</Typography>
                  <Typography variant="h6" fontWeight="600">
                    {payslipdata?.summary?.totalLeave}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      background: "#ffc10720",
                      p: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <MdBlock style={{ fontSize: "30px", color: "#ffc107" }} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            {/* <Button
              buttonName={"Create"}
              size="small"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "addpayslip",
                });
              }}
            /> */}
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              variant="contained"
              // onClick={toggleDrawer}
              onClick={handleFilterClick}
              sx={{ mt: 2 }}
            />
          </Stack>
        </Stack>
        <Collapse Collapse in={showFilter}>
          <Stack
            mb={"12px"}
            sx={{ background: "#151515" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Typography className="inputfont">{"Company"}:</Typography>
              <CommonSelect
                name="companyId"
                value={formik1.values.companyId}
                formik={formik1}
                selectList={companyData}
                onChange={(e) =>
                  formik1.setFieldValue("companyId", e.target.value)
                }
                selectHead="Select Company"
              />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"Employee"}:</Typography>
              <CommonSelect
                name="employee"
                value={formik1.values.employee}
                formik={formik1}
                selectList={employeeData}
                selectHead="Select Employee"
                onChange={(e) =>
                  formik1.setFieldValue("employee", e.target.value)
                }
              />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"Date"}:</Typography>
              <CommonInput
                name="date"
                value={formik1.values.date}
                formik={formik1}
                type="month"
                placeholder="Date"
              />
            </Grid>

            <Grid className="filterinput">
              <Typography className="inputfont">{"Type"}:</Typography>
              <CommonSelect
                name="status"
                value={formik1.values.status}
                formik={formik1}
                selectList={[
                  {
                    id: 1,
                    name: "Paid",
                  },
                  {
                    id: 0,
                    name: "Unpaid",
                  },
                  {
                    id: 2,
                    name: "Partial",
                  },
                ]}
                selectHead="Select Status"
              />
            </Grid>

            <Box>
              <Button buttonName={"Filter"} onClick={formik1.handleSubmit} />
            </Box>
            <Box>
              <Button buttonName={"Clear"} onClick={() => clearFilter()} />
            </Box>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Employee</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Company</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Start Date</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>End Date</div>
                  </Stack>
                </TableCell>

                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Gross Pay</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Deduction</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Tax</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Net Pay</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Status</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Action</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payslipdata?.employeeData?.map((data) => (
                <TableRow
                  key={data.employee}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/view-payslip", {
                      state: {
                        id: data.id,
                      },
                    });
                  }}
                >
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.firstName} {data.lastName}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.name}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.startDate}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.endDate}
                  </TableCell>

                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.monthlySalary}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.deductions}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.tax_details}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.net_salary}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <FormControl>
                      <Select
                        value={data.status}
                        onChange={(event) => {
                          setStatusChangeData({
                            id: data.id,
                            status: event.target.value,
                          });

                          setIsModalOpen({
                            open: true,
                            currentComponent: "delete",
                            id: data.id,
                            para: "Are you sure you want to change the status?",
                          });
                        }}
                      >
                        <MenuItem value="1">Paid</MenuItem>
                        <MenuItem value="0">Unpaid</MenuItem>
                        <MenuItem value="2">Partial</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    justifyContent={"flex-end"}
                  >
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className="chatDropDown">
                        <MenuButton
                          icon={
                            <MdMoreVert
                              style={{ color: "#000", fontSize: "18px" }}
                            />
                          }
                          width="30px"
                          height="33px"
                        />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content
                        className="chatDropDownContent"
                        align="end"
                      >
                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() =>
                              navigate("/view-payslip", {
                                state: {
                                  id: data.id,
                                },
                              })
                            }
                            // onClick={handlePrint}
                          >
                            {/* <BsDownload style={{ fontSize: "15px" }} /> */}
                            <BsEye style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "15px" }}>{"View"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        {/* <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <EmailOutlined style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Send Email"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <HiOutlineTrash style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Delete"}</Box>
                          </Stack>
                        </DropdownMenu.Item> */}
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              ))}
              {payslipdata?.employeeData == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : payslipdata?.employeeData &&
                payslipdata?.employeeData.length == 0 ? (
                <TableRowsLoader colNumber={11} rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" });
          setFiles([]);
        }}
        handelClick={() => statusChange()}
        files={files}
        setFiles={setFiles}
        data={roledata}
      />
    </>
  );
};

export default PayslipGeneration;
