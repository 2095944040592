import { deleteRequest, get, post, put } from "../web.request";


export const CompanyDetail = async (id) => {
  return await get(`/companyDetail/${id}`);
}

export const CompanyList = async (query) => {
  return await get(`/companyList${query}`)
}

export const CompanyDropdown = async () => {
  return await get(`/companyDropdown`)
}

export const CreateCompany = async (data) => {
  return await post(`/createCompany `, data);
}

export const UpdateCompany = async (id, data) => {
  return await put(`/updateCompany/${id}`, data);
}

export const DeleteCompany = async (id) => {
  return await deleteRequest(`/deleteCompany/${id}`);
}

export const StatusChangeCompany = async (id, data) => {
  return await put(`/statusChangeCompany/${id}`, data);
}
