import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button } from "../../components/Common";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { leaveAddInitialValues } from "../../helper/initialValues";
import { leaveAddValidationSchema } from "../../helper/validation";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { EmployeeDropdown } from "../../Service/Employee.service";
import {
  AddLeave,
  LeaveDetail,
  LeaveTypeDropdown,
  LeaveBalance,
} from "../../Service/Leave.service";
import CommonInput from "../../components/Common/Input";
import CommonSelect from "../../components/Common/Select";

const AddLeaveModal = ({ handleClose, modalOpen, refresh, setRefresh }) => {
  const dispatch = useDispatch();
  const [EmployeeData, setEmployeeData] = useState([]);
  const [balanceData, setBalanceData] = useState(0);
  const [LeaveData, setLeaveTypeData] = useState([]);
  const formik = useFormik({
    initialValues: leaveAddInitialValues,
    onSubmit: async (value) => {
      const payload = {
        employeeId: value.employeeId,
        startDate: value.startDate,
        endDate: value.endDate,
        reason: value.reason,
        type: value.type,
      };

      let res = await AddLeave(payload);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        formik.resetForm();
        setRefresh(!refresh);
        handleClose();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: leaveAddValidationSchema,
  });

  const getDropdownData = async () => {
    let res = await EmployeeDropdown();
    if (res?.status) {
      setEmployeeData(res.data);
    } else {
      setEmployeeData([]);
    }
  };

  const getTypeDropdownData = async () => {
    let res = await LeaveTypeDropdown();
    if (res?.status) {
      setLeaveTypeData(res.data);
    } else {
      setLeaveTypeData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
    getTypeDropdownData();
  }, []);

  useEffect(() => {
    if (modalOpen.isEdit) {
      getHolidayDetails(modalOpen.id);
    }
  }, [modalOpen.isEdit]);

  const getHolidayDetails = async (id) => {
    let res = await LeaveDetail(id);
    if (res?.status) {
      formik.setValues({
        employeeId: res.data.employeeId,
        startDate: res.data.startDate,
        endDate: res.data.endDate,
        reason: res.data.reason,
        type: res.data.type,
      });
    }
  };

  useEffect(() => {
    if (formik.values.startDate > formik.values.endDate) {
      formik.setFieldValue("endDate", formik.values.startDate);
    }
  }, [formik.values.startDate]);

  const checkLeaveBalance = async (id) => {
    let res = await LeaveBalance(id);
    if (res?.status) {
      setBalanceData(res.data);
    } else {
      setBalanceData(0);
    }
  };

  useEffect(() => {
    if (formik.values.employeeId) {
      checkLeaveBalance(formik.values.employeeId);
    }
  }, [formik.values.employeeId]);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? "Edit Leave" : "Add Leave"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              Employee Name
            </Typography>
            <CommonSelect
              label="Employee Name"
              formik={formik}
              name="employeeId"
              onChange={(e) =>
                formik.setFieldValue("employeeId", e.target.value)
              }
              value={formik.values.employeeId}
              selectList={EmployeeData}
            />
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {formik.values.employeeId != "" &&
                (balanceData > 0 ? (
                  <span style={{ color: "green" }}>
                    Leave Balance: {balanceData}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    Leave Balance: {balanceData}
                  </span>
                ))}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <CommonInput
              labelinput="Reason"
              placeholder="Reason"
              formik={formik}
              name="reason"
              maxLength={255}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Type"}
            </Typography>

            <CommonSelect
              label="Type"
              formik={formik}
              name="type"
              onChange={(e) => formik.setFieldValue("type", e.target.value)}
              value={formik.values.type}
              selectList={LeaveData}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonInput
              labelinput="Start Date"
              type="date"
              formik={formik}
              min={new Date().toISOString().split("T")[0]}
              name="startDate"
              isDate={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonInput
              labelinput="End Date"
              type="date"
              formik={formik}
              name="endDate"
              min={formik.values.startDate}
              isDate={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Submit"
          size="small"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
};

export default AddLeaveModal;
