import React, { useState } from "react";
import { Button, Grid, Typography, Stack } from "@mui/material";
import AddEmployeeHeading from "./AddEmployeeHeading";
import CommonInput from "../../../components/Common/Input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Heading, Modal } from "../../../components/Common";

const IdentiDetailTab = ({ formik, onNext, onPrevious, isEdit, gotoBack }) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  return (
    <>
      <Stack mb={2}>
        {/* <AddEmployeeHeading isEdit={isEdit} /> */}
        <div>
          <Stack gap={2}>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <ArrowBackIcon
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "status",
                    para: "Are you sure you want to go back?",
                    head: "Warning",
                  });
                }}
                style={{
                  cursor: "pointer",
                  background: "#ffc107",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "4px",
                  fontSize: "25px",
                }}
              />{" "}
              <Heading head={isEdit ? "Edit Employee" : "Add Employee"} />
            </Stack>
          </Stack>
        </div>
      </Stack>
      <Stack className="Sideempdetails">
        <Typography variant="h6" className="personaldetailsHeading" mb={3}>
          Identification Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Pan Number"
              name="panNumber"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Aadhar Number"
              name="adharNumber"
              formik={formik}
              maxLength={20}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Passport Number"
              name="passportNumber"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Passport Valid Upto"
              name="passportValidupto"
              formik={formik}
              type="date"
              isDate={true}
            />
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          className="personaldetailsHeading"
          mb={3}
          style={{
            marginTop: "2rem",
          }}
        >
          Current Address
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}  sm={6} md={6}>
            <CommonInput
              labelinput="Current Address Line 1"
              name="cLine1"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={12}  sm={6} md={6}>
            <CommonInput
              labelinput="Current Address Line 2"
              name="cLine2"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4}>
            <CommonInput
              labelinput="City"
              name="cCity"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4}>
            <CommonInput
              labelinput="State"
              name="cState"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4}>
            <CommonInput
              labelinput="Pincode"
              name="cZipcode"
              formik={formik}
              maxLength={50}
            />
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          className="personaldetailsHeading"
          mb={3}
          style={{
            marginTop: "2rem",
          }}
        >
          Permanent Address
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}  sm={6} md={6}>
            <CommonInput
              labelinput="Permanent Address Line 1"
              name="pLine1"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={12}  sm={6} md={6}>
            <CommonInput
              labelinput="Permanent Address Line 2"
              name="pLine2"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4}>
            <CommonInput
              labelinput="City"
              name="pCity"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4}>
            <CommonInput
              labelinput="State"
              name="pState"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4}>
            <CommonInput
              labelinput="Pincode"
              name="pZipcode"
              formik={formik}
              maxLength={50}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={() => onPrevious()}
            variant="outlined"
            color="secondary"
          >
            Previous
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={formik.handleSubmit}
          >
            Next
          </Button>
        </Grid>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={gotoBack}
      />
    </>
  );
};

export default IdentiDetailTab;
