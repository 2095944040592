import React, { useState, useEffect } from "react";
import { Button, Heading, Input, Modal } from "../../../components/Common";
import {
  Box,
  Collapse,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Search from "../../../components/Common/Table/Search";
import { VscFilter } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  salaryInitialValues,
  salarycalculateInitialValues,
} from "../../../helper/initialValues";
import { CalculateSalary, SalaryList } from "../../../Service/Salary.service";
import CommonSelect from "../../../components/Common/Select";
import { EmployeeDropdown } from "../../../Service/Employee.service";
import { CompanyDropdown } from "../../../Service/Company.service";
import CommonInput from "../../../components/Common/Input";
import moment from "moment";
import { addSalaryValidationSchema } from "../../../helper/validation";
import SalaryCalculate from "../../../components/Common/modals/SalaryCalculate";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import TableRowsLoader from "../../../components/Common/Loader/Skeleton";

const EmployeeSalary = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [roleData, setRoleData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [EmployeeSalary, setEmployeeSalary] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [showFilter, setShowFilter] = useState(false);

  const dispatch = useDispatch();

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
    formik1.resetForm();
    formik1.handleSubmit();
  };

  const getDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  const formik1 = useFormik({
    initialValues: salaryInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getSalarylist();
    },
  });

  const formik = useFormik({
    initialValues: salarycalculateInitialValues,
    onSubmit: async (value) => {
      const payload = {
        companyId: value.companyId,
      };

      console.log("payload", payload);

      let res = await CalculateSalary(payload);

      console.log("res", res);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );

        formik.resetForm();
        getSalarylist();
        setIsModalOpen({ open: false, currentComponent: "" });
      } else {
        setIsModalOpen({ open: false, currentComponent: "" });
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: addSalaryValidationSchema,
  });

  useEffect(() => {
    getEmployeeData();
  }, []);

  const getEmployeeData = async () => {
    let res = await EmployeeDropdown();
    if (res?.status) {
      setEmployeeData(res.data);
    } else {
      setEmployeeData([]);
    }
  };

  const getSalarylist = async () => {
    setRoleData([]);
    let selectQry = `?page=${page}&limit=${rowsPerPage}&search=${search}&status=${formik1.values.status}&sortBy=${sortConfig.key}&sortFlag=${sortConfig.direction}&companyId=${formik1.values.companyId}&employeeId=${formik1.values.employee}&date=${formik1.values.date}`;

    let res = await SalaryList(selectQry);
    if (res?.status) {
      setEmployeeSalary(res.data);
      setTotalData(res.total);
    } else {
      setEmployeeSalary(null);
      setRoleData(null);
    }
  };

  useEffect(() => {
    getSalarylist();
  }, [search, page, rowsPerPage, sortConfig]);

  return (
    <>
      <Stack gap={1}>
        <Heading head={"Employee Salary"} />
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button
              buttonName={"Calculate Salary"}
              size="small"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "salarycalculate",
                });
              }}
            />
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              variant="contained"
              onClick={handleFilterClick}
              sx={{ mt: 2 }}
            />
          </Stack>
        </Stack>
        <Collapse Collapse in={showFilter}>
          <Stack
            mb={"12px"}
            sx={{ background: "#151515" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Typography className="inputfont">{"Company"}:</Typography>
              <CommonSelect
                name="companyId"
                value={formik1.values.companyId}
                formik={formik1}
                selectList={companyData}
                onChange={(e) =>
                  formik1.setFieldValue("companyId", e.target.value)
                }
                selectHead="Select Company"
              />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"Employee"}:</Typography>
              <CommonSelect
                name="employee"
                value={formik1.values.employee}
                formik={formik1}
                selectList={employeeData}
                selectHead="Select Employee"
                onChange={(e) =>
                  formik1.setFieldValue("employee", e.target.value)
                }
              />
            </Grid>

            <Grid className="filterinput">
              <Typography className="inputfont">{"Date"}:</Typography>
              <CommonInput
                name="date"
                value={formik1.values.date}
                formik={formik1}
                type="month"
                placeholder="Date"
              />
            </Grid>

            <Box>
              <Button buttonName={"Filter"} onClick={formik1.handleSubmit} />
            </Box>
            <Box>
              <Button buttonName={"Clear"} onClick={() => clearFilter()} />
            </Box>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Gross Salary</TableCell>
                <TableCell>Total Leave</TableCell>
                <TableCell>LOP</TableCell>
                <TableCell>Tax</TableCell>
                <TableCell>Sequrity Deposit</TableCell>
                <TableCell>Net Salary</TableCell>
                <TableCell>Date</TableCell>
                {/* <TableCell align="center">Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {EmployeeSalary?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.firstName + " " + row?.lastName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.designation}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.contact}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.email}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.monthly_salary}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.leave}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.Lop}
                  </TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    {row.tax_details}
                  </TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    {row?.deductions ? row?.deductions.toFixed(2) : 0}
                  </TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    {row.net_salary ? row?.net_salary.toFixed(2) : 0}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {moment(row.createdAt).format("MMM")}
                  </TableCell>
                </TableRow>
              ))}
              {EmployeeSalary == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : EmployeeSalary && EmployeeSalary.length == 0 ? (
                <TableRowsLoader colNumber={11} rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "" });
          formik.resetForm();
          formik.handleSubmit();
        }}
        formik={formik}
      />
    </>
  );
};

export default EmployeeSalary;
