import React, { useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button, Input } from "../../components/Common";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { holidayAddInitialValues } from '../../helper/initialValues';
import { holidayAddValidationSchema } from '../../helper/validation';
import { useDispatch } from 'react-redux';
import { CreateHoliday, HolidayDetail, UpdateHoliday } from '../../Service/Holiday.service';
import { CompanyDropdown } from '../../Service/Company.service';
import { notificationSuccess } from '../../state/Action/NotificationAction';

const AddHolidayModal = ({ handleClose, modalOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState([]);

  const formik = useFormik({
    initialValues: holidayAddInitialValues,
    onSubmit: async (value) => {
      const payload = {
        companyId: value.companyId,
        title: value.title,
        date: value.date,
        day: value.day,
        description: value.description,
        type: value.type,
      };

      let res = modalOpen.isEdit ? await UpdateHoliday(modalOpen.id, payload) : await CreateHoliday(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success',
        }));
        formik.resetForm();
        navigate('/holiday');
        handleClose();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.',
        }));
      }
    },
    validationSchema: holidayAddValidationSchema,
  });

  const getDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (modalOpen.isEdit) {
      getHolidayDetails(modalOpen.id);
    }
  }, [modalOpen.isEdit]);

  const getHolidayDetails = async (id) => {
    let res = await HolidayDetail(id);
    if (res?.status) {
      formik.setValues({
        companyId: res.data.companyId,
        title: res.data.title,
        date: res.data.date,
        day: res.data.day,
        description: res.data.description,
        type: res.data.type,
      });
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? 'Edit Holiday' : 'Add Holiday'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput="Holiday Title"
              type="text"
              {...formik.getFieldProps('title')}
              maxLength={50}
              error={formik.touched.title && formik.errors.title}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={{ xs: "10px", sm: "13px" }} fontWeight={500} mb={"2px"}>
              Company Name
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                labelId="company-label"
                id="company"
                {...formik.getFieldProps('companyId')}
                error={formik.touched.companyId && formik.errors.companyId}
              >
                {companyData.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="Date"
              type="date"
              {...formik.getFieldProps('date')}
              error={formik.touched.date && formik.errors.date}
              helperText={formik.touched.date && formik.errors.date}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="Day"
              type="text"
              {...formik.getFieldProps('day')}
              error={formik.touched.day && formik.errors.day}
              helperText={formik.touched.day && formik.errors.day}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput="Description"
              type="text"
              {...formik.getFieldProps('description')}
              maxLength={255}
              error={formik.touched.description && formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={{ xs: "10px", sm: "13px" }} fontWeight={500} mb={"2px"}>
              {"Type"}
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                labelId="type-label"
                id="type"
                {...formik.getFieldProps('type')}
                error={formik.touched.type && formik.errors.type}
              >
                <MenuItem value="1">Public</MenuItem>
                <MenuItem value="2">Composite</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={"Cancel"} size="small" color="white" onClick={handleClose} />
        <Button buttonName="Submit" size="small" onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  );
};

export default AddHolidayModal;
