import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, Stack, FormControl } from "@mui/material";
import AddEmployeeHeading from "./AddEmployeeHeading";
import { CompanyDropdown } from "../../../Service/Company.service";
import CommonSelect from "../../../components/Common/Select";
import CommonInput from "../../../components/Common/Input";
import { CreateEmpPart1 } from "../../../Service/Employee.service";
import { BackButton, Heading, Modal } from "../../../components/Common";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const EmployeeDataTab = ({ formik, isEdit, gotoBack }) => {
  const [companyData, setCompanyData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  const getDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  const reset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Stack mb={2}>
        {/* <AddEmployeeHeading isEdit={isEdit} /> */}
        <div>
          <Stack gap={2}>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <ArrowBackIcon
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "status",
                    para: "Are you sure you want to go back?",
                    head: "Warning",
                  });
                }}
                style={{
                  cursor: "pointer",
                  background: "#ffc107",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "4px",
                  fontSize: "25px",
                }}
              />{" "}
              <Heading head={isEdit ? "Edit Employee" : "Add Employee"} />
            </Stack>
          </Stack>
        </div>
      </Stack>
      <Stack className="Sideempdetails">
        <Typography variant="h6" className="personaldetailsHeading" mb={3}>
          Basic Information
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonSelect
              selectList={companyData}
              labelinput="Company Name"
              formik={formik}
              onChange={(e) =>
                formik.setFieldValue("companyId", e.target.value)
              }
              name="companyId"
              value={formik.values.companyId}
            />
          </Grid>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Employee Code"
              formik={formik}
              name="empCode"
              maxLength={15}
              disabled={formik.values.id != ""}
            />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          className="personaldetailsHeading"
          mb={3}
        ></Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonSelect
              selectList={[
                { id: "Mr", name: "Mr" },
                { id: "Mrs", name: "Mrs" },
                { id: "Ms", name: "Miss" },
              ]}
              labelinput="Salutation"
              formik={formik}
              onChange={(e) =>
                formik.setFieldValue("salutation", e.target.value)
              }
              value={formik.values.salutation}
              name="salutation"
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="First Name"
              formik={formik}
              name="firstName"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Middle Name"
              formik={formik}
              name="middleName"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Last Name"
              formik={formik}
              name="lastName"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonSelect
              selectList={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
                { id: "Other", name: "Other" },
              ]}
              formik={formik}
              labelinput="Gender"
              onChange={(e) => formik.setFieldValue("gender", e.target.value)}
              value={formik.values.gender}
              name="gender"
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Date of Birth"
              formik={formik}
              name="DOB"
              type="date"
              isDate={true}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonSelect
              selectList={[
                { id: "A+", name: "A+" },
                { id: "A-", name: "A-" },
                { id: "B+", name: "B+" },
                { id: "B-", name: "B-" },
                { id: "O+", name: "O+" },
                { id: "O-", name: "O-+" },
                { id: "AB+", name: "AB+" },
                { id: "AB-", name: "AB-" },
              ]}
              formik={formik}
              labelinput="Blood Group"
              onChange={(e) =>
                formik.setFieldValue("bloodGroup", e.target.value)
              }
              value={formik.values.bloodGroup}
              name="bloodGroup"
            />
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          className="personaldetailsHeading"
          mb={3}
          style={{
            marginTop: "2rem",
          }}
        >
          Contact Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Contact Number"
              formik={formik}
              name="contact"
              maxLength={13}
              minLength={10}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Alternate Number (Optional)"
              formik={formik}
              name="altContact"
              maxLength={13}
              minLength={10}
            />
          </Grid>

          {/* <Grid item xs={3} sm={3}>
            <CommonInput
              labelinput="Email ID (Official)"
              formik={formik}
              name="email"
              maxLength={50}
            />
          </Grid> */}

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Email ID (Personal)"
              formik={formik}
              name="perosnalEmail"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Emergency Contact Number"
              formik={formik}
              name="emergencyContact"
              maxLength={20}
              minLength={10}
            />
          </Grid>

          {/* <Grid item xs={3} sm={3}>
            <CommonInput
              labelinput="Designation"
              formik={formik}
              name="designation"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <CommonInput
              labelinput="Departments"
              formik={formik}
              name="departments"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <CommonInput
              labelinput="Branch"
              formik={formik}
              name="branch"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <CommonInput
              labelinput="Joining Date"
              formik={formik}
              name="joining_date"
              type="date"
              isDate={true}
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <CommonSelect
              selectList={[
                { id: "Full-time", name: "Full-time" },
                { id: "Part-time", name: "Part-time" },
                { id: "Contract", name: "Contract" },
              ]}
              labelinput="Employment Type"
              formik={formik}
              onChange={(e) =>
                formik.setFieldValue("employeementType", e.target.value)
              }
              name="employeementType"
              value={formik.values.employeementType}
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <CommonSelect
              selectList={[
                { id: 1, name: "Active" },
                { id: 0, name: "Inactive" },
              ]}
              labelinput="Employment Status"
              formik={formik}
              onChange={(e) =>
                formik.setFieldValue("employeementStatus", e.target.value)
              }
              name="employeementStatus"
              value={formik.values.employeementStatus}
            />
          </Grid> */}
        </Grid>

        <Typography
          variant="h6"
          className="personaldetailsHeading"
          mb={3}
          style={{
            marginTop: "2rem",
          }}
        >
          Personal Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonSelect
              selectList={[
                { id: "Married", name: "Married" },
                { id: "Unmarried", name: "Unmarried" },
                { id: "Separated", name: "Separated" },
                { id: "Widow", name: "Widow" },
              ]}
              labelinput="Marital Status"
              formik={formik}
              value={formik.values.maritalStatus}
              onChange={(e) =>
                formik.setFieldValue("maritalStatus", e.target.value)
              }
              name="maritalStatus"
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Spouse Name (Optional)"
              maxLength={50}
              formik={formik}
              name="spouseName"
            />
          </Grid>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Spouse Contact (Optional)"
              maxLength={15}
              formik={formik}
              name="spouseContact"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Button type="button" onClick={() => reset()} color="secondary">
            Reset
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={formik.handleSubmit}
          >
            Next
          </Button>
        </Grid>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={gotoBack}
      />
    </>
  );
};

export default EmployeeDataTab;
