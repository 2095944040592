import { deleteRequest, get, post, put } from "../web.request";

export const SalaryList = async (query) => {
  return await get(`salaryList${query}`);
}

export const SalaryCreate = async (data) => {
  return await post(`createSalary`, data);
}


export const salaryDetail = async (id) => {
  return await get(`salaryDetail/${id}`);
}

export const IncrementHistory = async (id) => {
  return await get(`incrementHistory/${id}`);
}

export const CreateIncrement = async (data) => {
  return await post(`createIncrement`, data);
}

export const CalculateSalary = async (data) => {
  return await post(`createSalary`, data);
}

export const SalaryDeduction = async (data) => {
  return await put(`salaryDeduction`, data);
}