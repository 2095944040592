import { deleteRequest, get, post, put } from "../web.request";

export const payslipList = async (query) => {
  return await get(`payslipList${query}`);
}

export const payslipDetail = async (id) => {
  return await get(`payslipDetail/${id}`);
}

export const payslipStatusChange = async (data) => {
  return await put(`payslipStatusChange`, data);
}



