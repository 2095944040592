import { deleteRequest, get, post, put } from "../web.request";


export const DocumentDetail = async (id) => {
  return await get(`/documentDetail/${id}`);
}

export const AddDocument = async (data) => {
  return await post(`/addDocument `, data);
}

export const UpdateDocument = async (id, data) => {
  return await put(`/updateDocument/${id}`, data);
}

export const DeleteDocument = async (id) => {
  return await deleteRequest(`/deleteDocument/${id}`);
}

//deleteSpecificDocument
export const deleteSpecificDocument = async (id, type) => {
  return await deleteRequest(`deleteSpecificDocument/${id}/${type}`);
}
