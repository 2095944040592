import { deleteRequest, get, post, put } from "../web.request";

export const LeaveList = async (query) => {
  return await get(`/leaveList${query}`)
}

export const LeaveTypeDropdown = async () => {
  return await get(`/leaveTypeDropdown`)
}

export const AddLeave = async (data) => {
  return await post(`/addLeave `, data);
}

export const LeaveSettlement = async (data, id) => {
  return await post(`/leaveSettlement/${id} `, data);
}

export const UpdateHoliday = async (id, data) => {
  return await put(`/updateHoliday/${id}`, data);
}

export const LeaveDetail = async (id) => {
  return await get(`/leaveDetail/${id}`);
}

export const ConfirmLeave = async (id) => {
  return await put(`/confirmLeave/${id}`);
}

export const RejectLeave = async (id) => {
  return await put(`/rejectLeave/${id}`);
}
export const LeaveBalance = async (id) => {
  return await get(`/employeeLeavebalance/${id}`);
}