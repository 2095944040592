import React, { useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button } from "../../components/Common";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { LeaveSettlementInitialVaue } from '../../helper/initialValues';
import { leaveSettlementAddValidationSchema } from '../../helper/validation';
import { useDispatch } from 'react-redux';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { EmployeeDropdown } from '../../Service/Employee.service';
import { LeaveSettlement, LeaveBalance } from '../../Service/Leave.service';
import CommonInput from '../../components/Common/Input';
import CommonSelect from '../../components/Common/Select';

const AddLeaveManage = ({ handleClose, modalOpen, refresh, setRefresh }) => {
    const dispatch = useDispatch();
    const [EmployeeData, setEmployeeData] = useState([]);
    const [balanceData, setBalanceData] = useState(0);

    const formik = useFormik({
        initialValues: LeaveSettlementInitialVaue,
        onSubmit: async (value) => {
            const payload = {
                employeeId: value.employeeId,
                newLeaves: value.newLeaves,
            };
            let res = await LeaveSettlement(payload, value.employeeId);
            if (res?.status) {
                dispatch(notificationSuccess({
                    show: true,
                    type: 'success',
                    message: res.message || 'Success',
                }));
                formik.resetForm();
                handleClose();
            } else {
                dispatch(notificationSuccess({
                    show: true,
                    type: 'error',
                    message: res.message || 'Something went wrong. Please try again later.',
                }));
            }
        },
        validationSchema: leaveSettlementAddValidationSchema,
    });

    const getDropdownData = async () => {
        let res = await EmployeeDropdown();
        if (res?.status) {
            setEmployeeData(res.data);
        } else {
            setEmployeeData([]);
        }
    };

    useEffect(() => {
        getDropdownData();
    }, []);

    const checkLeaveBalance = async (id) => {
        let res = await LeaveBalance(id);
        if (res?.status) {
            setBalanceData(res.data);
        } else {
            setBalanceData(0);
        }
    }

    useEffect(() => {
        if (formik.values.employeeId) {
            checkLeaveBalance(formik.values.employeeId);
        }
    }, [formik.values.employeeId]);

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Emp. Leave Settlement
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2} maxWidth={{ sm: "900px" }} paddingY={1}>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: "10px", sm: "13px" }} fontWeight={500} mb={"2px"}>
                            Employee Name
                        </Typography>
                        <CommonSelect
                            label="Employee Name"
                            formik={formik}
                            name="employeeId"
                            onChange={(e) => formik.setFieldValue('employeeId', e.target.value)}
                            value={formik.values.employeeId}
                            selectList={EmployeeData}
                        />
                        <Typography fontSize={{ xs: "10px", sm: "13px" }} fontWeight={500} mb={"2px"}>
                            {
                                formik.values.employeeId != '' && (balanceData > 0 ? <span style={{ color: 'green' }}>Leave Balance: {balanceData}</span> : <span style={{ color: 'red' }}>Leave Balance: {balanceData}</span>)
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CommonInput
                            labelinput="Add New Leave"
                            placeholder="Enter New Leave"
                            formik={formik}
                            name="newLeaves"
                            maxLength={200}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={"Cancel"} size="small" color="white" onClick={handleClose} />
                <Button buttonName="Submit" size="small" onClick={formik.handleSubmit} />
            </DialogActions>
        </>
    );
};

export default AddLeaveManage;
