import React, { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Input,
  MenuButton,
  Modal,
} from "../../components/Common";
import {
  Box,
  Collapse,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { VscFilter } from "react-icons/vsc";
import Search from "../../components/Common/Table/Search";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  AdminList,
  AdminCreate,
  AdminUpdate,
  AdminDelete,
  AdminStatusChange,
  ChnagePasswordOnMail,
} from "../../Service/Admin.service";
import { addAdminValidationSchema } from "../../helper/validation";
import {
  addAdminInitialValues,
  filterAdminInitialValues,
} from "../../helper/initialValues";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { RoleDropdown } from "../../Service/Role.service";
import moment from "moment";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import { Pagination } from "../../components/Common/Table";
import CommonSelect from "../../components/Common/Select";

const UserManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [admindata, setAdminData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [roledata, setRoleData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: addAdminInitialValues,
    onSubmit: async (value) => {
      const payload = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: value.role,
      };

      let res = isModalOpen?.isEdit
        ? await AdminUpdate(isModalOpen?.id, payload)
        : await AdminCreate(payload);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        getAdminList();
        setIsModalOpen({
          open: false,
          currentComponent: "",
          para: "",
          head: "",
          isEdit: false,
          isView: false,
        });
        formik.resetForm();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: addAdminValidationSchema,
  });

  const formik1 = useFormik({
    initialValues: filterAdminInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getAdminList();
    },
  });
  console.log("formik1", formik1);

  const getAdminList = async () => {
    setAdminData([]);
    let selectQry = `?page=${
      page + 1
    }&limit=${rowsPerPage}&search=${search}&status=${
      formik1.values.status
    }&role=${formik1.values.role}&sortBy=${sortConfig.key}&sortFlag=${
      sortConfig.direction
    }`;
    let res = await AdminList(selectQry);
    if (res?.status) {
      setAdminData(res.data);
      setTotalData(res.total);
    } else {
      setAdminData(null);
    }
  };

  useEffect(() => {
    getAdminList();
  }, [search, page, rowsPerPage, sortConfig]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await AdminStatusChange(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
        ? await AdminDelete(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "changepassword"
        ? await ChnagePasswordOnMail(isModalOpen?.id)
        : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getAdminList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };

  const getDropdownData = async () => {
    let res = await RoleDropdown();
    if (res?.status) {
      setRoleData(res.data);
    } else {
      setRoleData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
    formik1.resetForm();
    getAdminList();
  };
  const [status, setstatus] = useState("");

  const handleStatus = (event) => {
    setstatus(event.target.value);
  };
  return (
    <>
      <Stack gap={2}>
        <Heading head={"User"} />
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"end"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button
              buttonName={"Create"}
              size="small"
              onClick={() => navigate("/add-user")}
            />
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              variant="contained"
              onClick={handleFilterClick}
              sx={{ mt: 2 }}
            />
          </Stack>
        </Stack>
        <Collapse Collapse in={showFilter}>
          <Stack
            mb={"12px"}
            sx={{ background: "#151515" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1}
            alignItems={"end"}
          >
            <Grid className="filterinput">
              <Typography className="inputfont">{"Role"}:</Typography>
              <CommonSelect
                name="role"
                value={formik1.values.role}
                formik={formik1}
                selectList={roledata}
                onChange={(e) => formik1.setFieldValue("role", e.target.value)}
                selectHead="Select Role"
              />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"CreatedDate"}:</Typography>
              <Input type="date" />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"Status"}:</Typography>
              <CommonSelect
                name="status"
                value={formik1.values.status}
                formik={formik1}
                selectList={[
                  { id: 1, name: "Active" },
                  { id: 0, name: "Inactive" },
                  { id: 2, name: "Delete" },
                ]}
                onChange={(e) =>
                  formik1.setFieldValue("status", e.target.value)
                }
                selectHead="Select Status"
              />
            </Grid>
            <Box>
              <Button buttonName={"Filter"} onClick={formik1.handleSubmit} />
            </Box>
            <Box>
              <Button buttonName={"Clear"} onClick={() => clearFilter()} />
            </Box>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Image</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Name</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Company Name</div>
                  </Stack>
                </TableCell>

                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Email</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Created Date</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Role</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Status</div>
                  </Stack>
                </TableCell>

                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"left"}
                  >
                    <div>Action</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admindata?.map((data) => (
                <TableRow
                  key={data.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                >
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data?.profile ? (
                      <img
                        src={data?.profile}
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewuser",
                        isEdit: true,
                        id: data?.id,
                      });
                    }}
                  >
                    {data.firstName} {data.lastName}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewuser",
                        isEdit: true,
                        id: data?.id,
                      });
                    }}
                  >
                    {data.companyname}
                  </TableCell>

                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data.email}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewuser",
                        isEdit: true,
                        id: data?.id,
                      });
                    }}
                  >
                    {moment(data?.createdAt).format("DD/MM/YYYY")}
                  </TableCell>

                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      setIsModalOpen({
                        open: true,
                        currentComponent: "viewuser",
                        isEdit: true,
                        id: data?.id,
                      });
                    }}
                  >
                    {data.rolename}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    <Switch
                      size="small"
                      checked={data?.status === 1 ? true : false}
                      disabled={formik1.values.status == 2 ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({
                          id: data?.id,
                          open: true,
                          currentComponent: "status",
                          head: "Change Status",
                          para: "Are you sure you want to Change the Status?",
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className="chatDropDown">
                        <MenuButton
                          icon={
                            <MdMoreVert
                              style={{ color: "#000", fontSize: "18px" }}
                            />
                          }
                          width="30px"
                          height="33px"
                        />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content
                        className="chatDropDownContent"
                        align="end"
                      >
                        <DropdownMenu.Item
                          className="chatDropDownMenu"
                          onClick={() => {
                            navigate("/add-user", {
                              state: { id: data?.id, isEdit: true },
                            });
                          }}
                        >
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <AiOutlineEdit style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Edit"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        {/* <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <IoEyeOutline style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"View"}</Box>
                          </Stack>
                        </DropdownMenu.Item> */}

                        <DropdownMenu.Item
                          className="chatDropDownMenu"
                          onClick={() =>
                            setIsModalOpen({
                              open: true,
                              currentComponent: "delete",
                              head: "DeleteAdmin",
                              para: "Are you sure you want to Delete this User?",
                              id: data?.id,
                            })
                          }
                        >
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <HiOutlineTrash style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Delete"}</Box>
                          </Stack>
                        </DropdownMenu.Item>

                        <DropdownMenu.Item
                          className="chatDropDownMenu"
                          onClick={() =>
                            setIsModalOpen({
                              id: data.id,
                              open: true,
                              currentComponent: "changepassword",
                              head: "Change Password",
                              para: "Are you sure you want to Change Password of this Admin",
                            })
                          }
                        >
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <RiLockPasswordLine style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Reset Password"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              ))}
              {admindata == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : admindata && admindata.length == 0 ? (
                <TableRowsLoader colNumber={7} rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {admindata != null && (
          <Pagination
            count={10}
            shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({
            open: false,
            currentComponent: "",
            id: "",
            isEdit: "",
          });
          setFiles([]);
        }}
        files={files}
        setFiles={setFiles}
        data={roledata}
        handelClick={changeStatus}
      />
    </>
  );
};

export default UserManagement;
