import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "..";
import { CompanyDropdown } from "../../../Service/Company.service";
import CommonSelect from "../Select";

const SalaryCalculate = ({ handleClose, handelClick, status, handleChange, formik }) => {
  const [companyData, setCompanyData] = useState([]);

  const getDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Salary Calculate
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent > 
        <Grid container spacing={2} paddingY={1} minWidth={{xs:'300px',sm:'500px'}}>

          <Grid item xs={12} sm={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              Company
            </Typography>
            <CommonSelect
              name="companyId"
              value={formik.values.companyId}
              formik={formik}
              selectList={companyData}
              onChange={(e) =>
                formik.setFieldValue("companyId", e.target.value)
              }
              selectHead="Select Company"
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName="Submit" size="small" onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  );
};

export default SalaryCalculate;