import React, { useState } from "react";
import { Button, Grid, Typography, Stack } from "@mui/material";
import AddEmployeeHeading from "./AddEmployeeHeading";
import CommonInput from "../../../components/Common/Input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Heading, Modal } from "../../../components/Common";

export const EduDetails = ({
  formik,
  onNext,
  onPrevious,
  isEdit,
  gotoBack,
}) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  return (
    <>
      <Stack mb={2}>
        {/* <AddEmployeeHeading isEdit={isEdit} /> */}
        <div>
          <Stack gap={2}>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <ArrowBackIcon
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "status",
                    para: "Are you sure you want to go back?",
                    head: "Warning",
                  });
                }}
                style={{
                  cursor: "pointer",
                  background: "#ffc107",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "4px",
                  fontSize: "25px",
                }}
              />{" "}
              <Heading head={isEdit ? "Edit Employee" : "Add Employee"} />
            </Stack>
          </Stack>
        </div>
      </Stack>
      <Stack className="Sideempdetails">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" className="personaldetailsHeading" mb={3}>
            Education Details
          </Typography>
        </div>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Education Level"
              name="educationLevel"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Course"
              name="course"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Specialization"
              name="specification"
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Start Year"
              name="startYear"
              type="month"
              isDate={true}
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="Passout Year"
              name="passoutYear"
              type="month"
              isDate={true}
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={6}  sm={4} md={3}>
            <CommonInput
              labelinput="CGPA"
              name="CGPA"
              formik={formik}
              maxLength={10}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={() => onPrevious()}
            variant="outlined"
            color="secondary"
          >
            Previous
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={formik.handleSubmit}
          >
            Finish
          </Button>
        </Grid>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={gotoBack}
      />
    </>
  );
};
