import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Heading, Modal } from "../../../src/components/Common";
import { AddHomeWorkOutlined, AddIcCallOutlined } from "@mui/icons-material";
import { MdCurrencyRupee } from "react-icons/md";

const salarycomponents = [
  {
    salarycompo: "Basic Pay",
    monthly: "19,500",
    Annual: "2,24,000",
    paytype: "Fixed Pay",
  },
  {
    salarycompo: "HRA",
    monthly: "10,000",
    Annual: "1,20,000",
    paytype: "Fixed Pay",
  },
  {
    salarycompo: "Transport Allowance",
    monthly: "2000",
    Annual: "24,000",
    paytype: "Fixed Pay",
  },
  {
    salarycompo: "Medical Allowance",
    monthly: "1250",
    Annual: "15,000",
    paytype: "Fixed Pay",
  },
  {
    salarycompo: "Other Allowance",
    monthly: "500",
    Annual: "6000",
    paytype: "Fixed Pay",
  },
];
const companycontribution = [
  {
    companycontribution: "PF",
    monthly: "1800",
    Annual: "21,600",
    paytype: "-",
  },
  {
    companycontribution: "ESI",
    monthly: "600",
    Annual: "7,200",
    paytype: "-",
  },
  // {
  //   companycontribution: "TDS",
  //   monthly: "-",
  //   Annual: "-",
  //   paytype: "-",
  // },
  // {
  //   companycontribution: "LWF",
  //   monthly: "-",
  //   Annual: "-",
  //   paytype: "-",
  // },
];
const deduction = [
  {
    deduction: "Professional Tax",
    monthly: "200",
    Annual: "2,400",
    paytype: "-",
  },
  {
    deduction: "Income Tax",
    monthly: "300",
    Annual: "3,600",
    paytype: "-",
  },
  {
    deduction: "LOP/LWP",
    monthly: "500",
    Annual: "6,000",
    paytype: "-",
  },
];

const SalaryandTaxMang = ({ employeeData, getEmployeeDetails }) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: "",
      head: "",
      isEdit: false,
    });
    getEmployeeDetails(employeeData.id);
  }, [refresh]);
  return (
    <div style={{ display: "flex", flex: "row", gap: "16px" }}>
      <Stack gap={2} width={"100%"}>
        {/* <Heading head={"Salary Structure"}/> */}
        <Stack className="Sideempdetails" gap={1}>
          <Stack>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={2}
            >
              <Box className="headingPart">
                <span className="mainH1">Salary Payment Details</span>
              </Box>
              <Button
                buttonName="Add Salary"
                startIcon={<MdCurrencyRupee />}
                style={{ float: "right" }}
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "Salary",
                    para: "",
                    head: "Add Salary Details",
                    isEdit: false,
                    isView: false,
                    id: "",
                    employeeData: employeeData,
                  });
                }}
              />
            </Box>
            <Stack gap={2}>
              <Divider />
              <Box className="salaryboxgrid">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Salary Payment Mode</span>
                  <span className="mainH2">Online transfer</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Reimbursement Payment Mode</span>
                  <span className="mainH2">Online transfer</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Tax Regime</span>
                  <span className="mainH2">OLD</span>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack className="Sideempdetails" gap={1}>
          <Stack>
            <Box className="headingPart">
              <span className="mainH1">Statutory details</span>
            </Box>
            <Stack gap={2}>
              <Divider />
              <Box className="salaryboxgrid">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">PF Number</span>
                  <span className="mainH2">APHYD11244123000010123</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">UAN</span>
                  <span className="mainH2">102379691253</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Employee PF Contribution limit</span>
                  <span className="mainH2">15000</span>
                </Box>
              </Box>
              <Box className="salaryboxgrid">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Professional Tax</span>
                  <span className="mainH2">250</span>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <TableContainer sx={{ borderColor: "#999797" }}>
            <Table>
              <TableHead>
                <TableCell style={{ width: "400px" }}>
                  Salary Components
                </TableCell>
                <TableCell>Monthly</TableCell>
                <TableCell>Annual</TableCell>
                <TableCell>Pay Type</TableCell>
              </TableHead>
              <TableBody style={{ backgroundColor: "white" }}>
                {salarycomponents.map((item) => (
                  <TableRow>
                    <TableCell>{item.salarycompo}</TableCell>
                    <TableCell>{item.monthly}</TableCell>
                    <TableCell>{item.Annual}</TableCell>
                    <TableCell>{item.paytype}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack>
          <TableContainer sx={{ borderColor: "#999797" }}>
            <Table>
              <TableHead>
                <TableCell style={{ width: "400px" }}>
                  Statutory Compliances
                </TableCell>
                <TableCell>Monthly</TableCell>
                <TableCell>Annual</TableCell>
                <TableCell>Pay Type</TableCell>
              </TableHead>
              <TableBody style={{ backgroundColor: "white" }}>
                {companycontribution.map((item) => (
                  <TableRow>
                    <TableCell>{item.companycontribution}</TableCell>
                    <TableCell>{item.monthly}</TableCell>
                    <TableCell>{item.Annual}</TableCell>
                    <TableCell>{item.paytype}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack>
          <TableContainer sx={{ borderColor: "#999797" }}>
            <Table>
              <TableHead>
                <TableCell style={{ width: "400px " }}>Deductions</TableCell>
                <TableCell>Monthly</TableCell>
                <TableCell>Annual</TableCell>
                <TableCell>Pay Type</TableCell>
              </TableHead>
              <TableBody style={{ backgroundColor: "white" }}>
                {deduction.map((item) => (
                  <TableRow>
                    <TableCell>{item.deduction}</TableCell>
                    <TableCell>{item.monthly}</TableCell>
                    <TableCell>{item.Annual}</TableCell>
                    <TableCell>{item.paytype}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      {/* <Stack
        className="Sideempdetails"
        gap={1}
        style={{ height: "fit-content",width:"350px" ,
          position: "sticky",
            top: "10px",
            zIndex: 1, 
        }}
      >
        <Stack>
          <Box className="headingPart">
            <span className="mainH1">Salary Details</span>
          </Box>
          <Stack gap={2}>
            <Divider />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap:"2px"
                }}
              >
                <span  style={{fontSize:"13px",color:"#999797"}}>Annual CTC :</span>
                <span className="mainH2"> ₹6,60,000.00 / Year</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap:"2px"

                }}
              >
                <span  style={{fontSize:"13px",color:"#999797"}}>Monthly CTC :</span>
                <span className="mainH2"> ₹55,000.00 / Month</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap:"2px"

                }}
              >
                <span  style={{fontSize:"13px",color:"#999797"}}>Effective Form :</span>
                <span className="mainH2"> Fabruary 2, 2024</span>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack> */}
      <Modal
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={() => {
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
            isEdit: false,
            isView: false,
            id: "",
          });
        }}
        setRefresh={setRefresh}
        refresh={refresh}
        handelClick={() => { }}
      />
    </div>
  );
};

export default SalaryandTaxMang;
