import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button } from "../../components/Common";

const ViewUserModel = ({ handleClose, modalOpen }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        User Details
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{minWidth:{xs:'100%',sm:'500px'}}}>
        <Grid container spacing={2}>
          <Box xs={12} item sx={{display:'flex', flexDirection:'column' , justifyContent:'center', alignItems:'center', width:'100%'}} >
            <Avatar   sx={{ width: 80, height: 80 }}/>
            <Typography variant="h6">User Name</Typography>
            <Typography variant="body1"> Bhargav</Typography>
          </Box>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Company Name</Typography>
            <Typography variant="body1">Asia Pacific</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Email</Typography>
            <Typography variant="body1">hrasiapacific@gmail.com</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Mobile Number</Typography>
            <Typography variant="body1">+91 87845646</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Created Date</Typography>
            <Typography variant="body1">23-05-2024</Typography>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Typography variant="h6">Role</Typography>
            <Typography variant="body1">HR</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Close"}
          size="small"
          color="white"
          onClick={handleClose}
        />
      </DialogActions>
    </>
  );
};

export default ViewUserModel;
