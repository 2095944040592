import React, { useState, useEffect } from "react";
import { Typography, Divider, Box, Stack } from "@mui/material";
import { Card, CardContent, List, ListItem, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, Modal } from "../../../components/Common";
import {
  CreateIncrement,
  IncrementHistory,
} from "../../../Service/Salary.service";
import { useFormik } from "formik";
import { incrementInitialValues } from "../../../helper/initialValues";
import moment from "moment";
import { addIncrementValidationSchema } from "../../../helper/validation";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";

const Timeline = styled(List)({
  position: "relative",
  paddingLeft: "20px",
  "&::before": {
    content: '""',
    position: "absolute",
    left: "10px",
    top: 0,
    bottom: 0,
    width: "2px",
    backgroundColor: "#a7a7a7",
  },
});

const Dot = styled("div")({
  position: "absolute",
  left: "-14px",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: "#a7a7a7",
  top: "20px",
});

const ListItemStyled = styled(ListItem)({
  position: "relative",
  paddingLeft: "30px",
});

const EmployeeHistory = (employeeId) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [historyData, setHistoryData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (employeeId) {
      getIncrementHistory(employeeId.employeeId);
    }
  }, [employeeId]);

  const getIncrementHistory = async (id) => {
    let res = await IncrementHistory(id);
    if (res?.status) {
      setHistoryData(res.data?.incrementData);
      formik.setFieldValue("basicSalary", res.data?.employeeData?.basicSalary);
    } else {
      setHistoryData();
    }
  };

  const formik = useFormik({
    initialValues: incrementInitialValues,
    onSubmit: async (value) => {
      const payload = {
        designation: value.designation,
        type: value.type,
        employeeId: employeeId.employeeId,
        totalSalary:
          formik.values.type == 1
            ? parseFloat(
                (formik.values.basicSalary * formik.values.amount) / 100
              ) + parseFloat(formik.values.basicSalary)
            : parseFloat(formik.values.basicSalary) +
              parseFloat(formik.values.amount),
        amount: value.amount,
        applicableFrom: value.applicableFrom,
        description: value.description,
      };

      let res = await CreateIncrement(payload);

      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        formik.resetForm();
        getIncrementHistory(employeeId.employeeId);
        setIsModalOpen({ open: false, currentComponent: "" });
      } else {
        setIsModalOpen({ open: false, currentComponent: "" });
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: addIncrementValidationSchema,
  });

  console.log("formikdsdsdsds", formik.errors);

  return (
    <>
      <Stack gap={3} sx={{ width: "100%" }}>
        <Stack className="Sideempdetails" gap={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="mainH1">Increment History</Typography>
            <Button
              buttonName="Add Increment"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "addincrement",
                });
              }}
            />
          </Box>
          <Divider />
          <Stack>
            <CardContent>
              {/* <Typography variant="h5" component="div">
          Employee History
        </Typography> */}
              <Timeline>
                {historyData.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItemStyled>
                      <Dot />
                      <ListItemText
                        primary={item.designation}
                        secondary={
                          <>
                            {moment(item.applicableFrom).format("DD-MM-YYYY")}

                            <br />
                            {item.description}
                          </>
                        }
                      />
                    </ListItemStyled>
                    {index < historyData.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Timeline>
            </CardContent>
          </Stack>
        </Stack>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "" });
          formik.resetForm();
          formik.handleSubmit();
        }}
        formik={formik}
      />
    </>
  );
};

export default EmployeeHistory;
