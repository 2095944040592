import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Typography, Stack } from "@mui/material";
import { Heading, Input, Modal } from "../../../components/Common";
import AddEmployeeHeading from "./AddEmployeeHeading";
import { BankValidationSchema } from "../../../helper/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import CommonInput from "../../../components/Common/Input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BankDetailsAdd = ({ formik, onNext, onPrevious, isEdit, gotoBack }) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  return (
    <>
      <Stack mb={2}>
        {/* <AddEmployeeHeading isEdit={isEdit} /> */}
        <div>
          <Stack gap={2}>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <ArrowBackIcon
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "status",
                    para: "Are you sure you want to go back?",
                    head: "Warning",
                  });
                }}
                style={{
                  cursor: "pointer",
                  background: "#ffc107",
                  color: "#fff",
                  borderRadius: "5px",
                  padding: "4px",
                  fontSize: "25px",
                }}
              />{" "}
              <Heading head={isEdit ? "Edit Employee" : "Add Employee"} />
            </Stack>
          </Stack>
        </div>
      </Stack>
      <Stack className="Sideempdetails">
        <Typography variant="h6" className="personaldetailsHeading" mb={3}>
          Bank Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}  sm={6} md={3}>
            <CommonInput
              labelinput="Name As Per Bank Record"
              name="nameAsPerBank"
              formik={formik}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={3}>
            <CommonInput
              labelinput="Bank Name"
              name="bankName"
              formik={formik}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={3}>
            <CommonInput
              labelinput="Bank Branch Name"
              name="bankBranch"
              formik={formik}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={3}>
            <CommonInput
              labelinput="Account Number"
              name="accountNumber"
              formik={formik}
              maxLength={30}
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={3}>
            <CommonInput
              labelinput="IFSC Number"
              name="IFSCNumber"
              formik={formik}
              maxLength={20}
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={3}>
            <CommonInput
              labelinput="UPI ID"
              name="upiId"
              formik={formik}
              maxLength={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2} display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={() => onPrevious()}
            variant="outlined"
            color="secondary"
          >
            Previous
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={formik.handleSubmit}
          >
            Next
          </Button>
        </Grid>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
        handelClick={gotoBack}
      />
    </>
  );
};

export default BankDetailsAdd;
